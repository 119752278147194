import React, { useContext, useState, useEffect } from "react";
import { useConfigurationProvider } from "../../../../services/soft-cache-service";
import { AutoLogoutFeatureConfigContextType } from "./data/types";
import { TIME_IN_MS } from "../../../../common/Utilities";

const initialState: AutoLogoutFeatureConfigContextType = {
  timeout: TIME_IN_MS.MINUTES * 5,
  countdownThreshold: TIME_IN_MS.MINUTES * 1,
  enabled: false,
  isLoading: true
};

const AutoLogoutFeatureConfigContext = React.createContext<AutoLogoutFeatureConfigContextType>(initialState);

export const useAutoLogoutFeatureConfigContext = () => {
  return useContext(AutoLogoutFeatureConfigContext);
};

export const AutoLogoutFeatureConfigContextProvider = ({ children }: { children: React.ReactNode }) => {
  const autoLogoutSolutionConfigProvider = useConfigurationProvider();

  const [timeout, setTimeout] = useState<number>(initialState.timeout);
  const [enabled, setEnabled] = useState<boolean>(initialState.enabled);
  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);
  const [countdownThreshold, setCountdownThreshold] = useState<number>(initialState.countdownThreshold);

  useEffect(() => {
    setIsLoading(true);
    const { autoLogoutControl } = autoLogoutSolutionConfigProvider.config.webClient;
    const {
      timeout: newTimeout = initialState.timeout,
      enabled: newEnabled = initialState.enabled,
      countdownThreshold: newCountdownThreshold = initialState.countdownThreshold
    } = autoLogoutControl || {};

    setTimeout(newTimeout || initialState.timeout);
    setEnabled(newEnabled || initialState.enabled);
    setCountdownThreshold(newCountdownThreshold || initialState.countdownThreshold);
    setIsLoading(false);
  }, [autoLogoutSolutionConfigProvider]);

  return (
    <AutoLogoutFeatureConfigContext.Provider
      value={{
        timeout,
        enabled,
        countdownThreshold,
        isLoading
      }}
    >
      {children}
    </AutoLogoutFeatureConfigContext.Provider>
  );
};
