import React from "react";
import Style from "../../PaginatedListTableWithTypes.module.css";

interface StatusProps {
  statusList: {
    id?: string;
    label: string;
    backgroundColor: string;
    textColor?: string;

    // old props for backwards compatibility
    color?: string;
    title?: string;
  }[];
}

export const Status = ({ statusList = [] }: StatusProps) => {
  return (
    <div className={`${Style.flex_row} ${Style.status_container}`}>
      {statusList?.length
        ? statusList.map((eachStatus) => {
            const { id, title, label, textColor = "white", backgroundColor, color } = eachStatus;
            const displayText = label || title;

            return (
              <div
                key={displayText}
                className={Style.status}
                style={{ backgroundColor: backgroundColor || color, color: textColor }}
                data-cy={`status${id ? `-${id}` : ""}`}
              >
                {displayText}
              </div>
            );
          })
        : "-"}
    </div>
  );
};
