import React, { useState, useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import Style from "./GlobalSearch.module.css";
import useDebounce from "../../hooks/use-debounce";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { multipleSortComparison } from "../../utils";
import useGlobalSearchConfigContext, {
  GlobalSearchConfigContextProvider
} from "./contexts/global-search-config-context";
import { getStatusFlags } from "../../common/Utilities";
import { getDetailsPageLink, getLabelByItemClass, getValueByType, shortenName, getLabelByItemTypeClass } from "./utils";
import useAvoidRacingAPIHelper from "../../hooks/use-avoid-racing-api-helper";

const MIN_SEARCH_LENGTH = 3;

const GlobalSearch = () => {
  const {
    isLoading: isConfigLoading,
    orderStatusFlagMap,
    assetStatusFlagMap,
    inventoryStatusFlagMap,
    orderPartStatusFlagMap,
    useV2Order,
    daysForHotOrder,
    resultDisplayControl,
    queryTransferOrders
  } = useGlobalSearchConfigContext();

  const [searchClient] = useState(useXemelgoClient().getSearchClient());

  const [inputString, setInputString] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const inputStringDebounced = useDebounce(inputString, 800);

  const searchFn = async () => {
    if (!inputString || inputString.length < MIN_SEARCH_LENGTH) {
      return [[], []];
    }
    const promiseList = [getItemSearchResults(), getItemTypeSearchResult()];
    return Promise.all(promiseList);
  };

  const executeSearchFn = useAvoidRacingAPIHelper();

  const { itemQueryFields, itemSearchFields, itemTypeQueryFields, itemTypeSearchFields } = useMemo(() => {
    return {
      itemQueryFields:
        resultDisplayControl?.item
          ?.filter(({ isQueryable }) => {
            return isQueryable;
          })
          ?.map(({ id }) => {
            return id;
          }) || [],
      itemSearchFields:
        resultDisplayControl?.item
          ?.filter(({ isSearchable }) => {
            return isSearchable;
          })
          ?.map(({ id }) => {
            return id;
          }) || [],
      itemTypeQueryFields:
        resultDisplayControl?.itemType
          ?.filter(({ isQueryable }) => {
            return isQueryable;
          })
          ?.map(({ id }) => {
            return id;
          }) || [],
      itemTypeSearchFields:
        resultDisplayControl?.itemType
          ?.filter(({ isSearchable }) => {
            return isSearchable;
          })
          ?.map(({ id }) => {
            return id;
          }) || []
    };
  }, [resultDisplayControl]);

  const getItemSearchResults = async () => {
    const queryFields = {
      item: itemQueryFields,
      itemType: itemTypeQueryFields
    };
    if (itemSearchFields?.length && itemQueryFields?.length) {
      const data = await searchClient.getItemsByFullTextSearch({
        searchString: inputString,
        limit: 50,
        pageCount: 0,
        daysForHot: daysForHotOrder,
        searchFields: itemSearchFields,
        queryFields,
        shouldQueryTransferOrders: queryTransferOrders
      });
      return data?.results || [];
    }
    return [];
  };

  const getItemTypeSearchResult = async () => {
    if (itemTypeSearchFields?.length && itemTypeQueryFields?.length) {
      const itemTypes = await searchClient.getItemTypesByFullTextSearch(
        inputString,
        itemTypeQueryFields,
        itemTypeSearchFields
      );
      return itemTypes;
    }
    return [];
  };

  const getStatusFlagMapConfigByItemClass = (itemClass) => {
    switch (itemClass) {
      case "Traveller":
        return orderStatusFlagMap;
      case "Asset":
        return assetStatusFlagMap;
      case "Inventory":
        return inventoryStatusFlagMap;
      case "Part":
        return orderPartStatusFlagMap;
      default:
        return [];
    }
  };

  const getLabelFromLabelTemplate = (templateId, item) => {
    switch (templateId) {
      case "getLabelByItemTypeClass":
        return getLabelByItemTypeClass(item.class);
      case "getLabelByItemClass":
        return getLabelByItemClass(item.itemClass, item.transferOrderClass);
      default:
        return "";
    }
  };

  const renderItemResult = (eachItem) => {
    const { item: itemDisplayControl } = resultDisplayControl || {};

    const statusFlags = useV2Order
      ? []
      : getStatusFlags(
          eachItem.customFields?.searchData?.status_flags || [],
          getStatusFlagMapConfigByItemClass(eachItem.itemClass)
        );

    return (
      <div className={Style.flex_row}>
        <div className={Style.flex_column}>
          {itemDisplayControl.map((eachFieldConfig, index) => {
            return renderEachField(eachItem, eachFieldConfig, index);
          })}
        </div>
        {!!statusFlags.length && (
          <div className={`${Style.flex_row} ${Style.status_container}`}>
            {statusFlags.map((flag, index) => {
              return (
                <div
                  key={`${flag.displayText}${index}`}
                  style={{ backgroundColor: flag.color }}
                  className={Style.status}
                >
                  <span className={Style.status_text}>{flag.displayText}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderItemTypeResult = (eachItemType) => {
    const { itemType: itemTypeDisplayControl } = resultDisplayControl || {};

    return (
      <div className={Style.flex_column}>
        {itemTypeDisplayControl.map((eachFieldConfig, index) => {
          return renderEachField(eachItemType, eachFieldConfig, index);
        })}
      </div>
    );
  };

  const renderEachField = (eachField, { id, type, label, labelTemplate, defaultValue, bold, autoHide }, index) => {
    const displayLabel = (labelTemplate && getLabelFromLabelTemplate(labelTemplate, eachField)) || label;
    const displayValue = eachField[id] ? shortenName(getValueByType(eachField[id], type)) : "";

    if (autoHide && !displayValue) {
      return null;
    }
    return (
      <div
        key={`${id} ${index}`}
        className={`${Style.flex_row} ${Style.label_container}`}
      >
        <p className={`${index === 0 ? Style.title_text : Style.text} ${(index === 0 || bold) && Style.bold}`}>
          {`${displayLabel}:`}
        </p>
        <p className={`${index === 0 ? Style.title_text : Style.text} ${(index === 0 || bold) && Style.bold}`}>
          {displayValue || defaultValue || "-"}
        </p>
      </div>
    );
  };

  const renderEachResult = (eachResult, nodeType) => {
    switch (nodeType) {
      case "itemType":
        return renderItemTypeResult(eachResult);
      case "item":
      default:
        return renderItemResult(eachResult);
    }
  };

  useEffect(() => {
    setIsSearchLoading(true);
    executeSearchFn(searchFn()).then(({ result, canceled }) => {
      if (!canceled) {
        const [itemResults = [], itemTypeResults = []] = result || [];
        setSearchResults({ itemResults: [...itemResults], itemTypeResults: [...itemTypeResults] });
        setIsSearchLoading(false);
      }
    });
  }, [inputStringDebounced]);

  const sortedResultsList = useMemo(() => {
    return [
      ...(searchResults?.itemTypeResults || [])
        .sort(multipleSortComparison({ id: "identifier" }))
        .map((eachItemType) => {
          return { ...eachItemType, resultType: "itemType", label: eachItemType.identifier };
        }),
      ...(searchResults?.itemResults || []).sort(multipleSortComparison([{ id: "identifier" }])).map((eachItem) => {
        return { ...eachItem, resultType: "item", label: eachItem.identifier };
      })
    ];
  }, [searchResults]);

  return (
    <div>
      <SearchDropdown
        inputValue={inputString}
        options={sortedResultsList}
        onChangeText={(text) => {
          if (text !== inputString) {
            setInputString(text);
            setIsSearchLoading(text.length >= MIN_SEARCH_LENGTH);
            if (text.length < MIN_SEARCH_LENGTH) {
              setSearchResults({});
            }
          }
        }}
        showSearchIcon
        placeholder="Search Xemelgo"
        textareaClassName={Style.text_area_background}
        renderContent={(_, dropdownBlurFn) => {
          if (isSearchLoading || isConfigLoading) {
            return [...Array(4)].map((_, index) => {
              return (
                <div
                  key={`skeleton_loading_${index}`}
                  className={`${Style.flex_row} ${Style.skeleton_loading_container}`}
                >
                  <div className={`${Style.flex_column} ${Style.skeleton_loading_label_container}`}>
                    <div className={Style.skeleton_loading_label_main}>
                      <Skeleton />
                    </div>
                    <div className={Style.skeleton_loading_label_sub}>
                      <Skeleton height={15} />
                    </div>
                  </div>
                  <div className={Style.skeleton_loading_status_container}>
                    <Skeleton />
                  </div>
                </div>
              );
            });
          }
          if (searchResults?.itemTypeResults?.length || searchResults.itemResults?.length) {
            return [
              ...searchResults.itemTypeResults
                .sort(multipleSortComparison([{ id: "identifier" }]))
                .map((eachItemType, index) => {
                  const { id } = eachItemType;
                  return (
                    <li
                      key={`${id}_${index}`}
                      className={Style.result_item_container}
                    >
                      <a
                        href={getDetailsPageLink("itemType", null, eachItemType.id)}
                        onClick={dropdownBlurFn}
                      >
                        {renderEachResult(eachItemType, "itemType")}
                      </a>
                    </li>
                  );
                }),
              ...searchResults.itemResults
                .sort(multipleSortComparison([{ id: "identifier" }]))
                .map((eachItem, index) => {
                  const { id, itemClass, transferOrderClass } = eachItem;
                  return (
                    <li
                      key={`${id}_${index}`}
                      className={Style.result_item_container}
                    >
                      <a
                        href={getDetailsPageLink("item", itemClass, id, transferOrderClass)}
                        onClick={dropdownBlurFn}
                        data-cy-global-search-suggestion-card
                      >
                        {renderEachResult(eachItem, "item")}
                      </a>
                    </li>
                  );
                })
            ];
          }
          return (
            <li className={Style.dropdown_no_results}>
              {inputString.length < MIN_SEARCH_LENGTH
                ? `Please enter to search (at least ${MIN_SEARCH_LENGTH} characters)`
                : "No matching results"}
            </li>
          );
        }}
      />
    </div>
  );
};

export default () => {
  return (
    <GlobalSearchConfigContextProvider>
      <GlobalSearch />
    </GlobalSearchConfigContextProvider>
  );
};
