import { useEffect, useCallback } from "react";
import { LocalCacheService } from "../../../../services/local-cache-service";

interface UseBrowserTabCloseTimeoutCheckProps {
  autoLogoutTimeout: number;
  onTimeout?: () => void;
  enabled?: boolean;
}

export const useBrowserTabCloseTimeoutCheck = ({
  autoLogoutTimeout,
  onTimeout,
  enabled
}: UseBrowserTabCloseTimeoutCheckProps) => {
  const handleBeforeUnload = useCallback(() => {
    LocalCacheService.saveCloseBrowserTabTimestamp(Date.now());
  }, []);

  useEffect(() => {
    if (enabled) {
      // Check if we need to logout on mount
      const lastActivityTimestamp = LocalCacheService.getCloseBrowserTabTimestamp();

      if (lastActivityTimestamp) {
        const timeSinceLastActivity = Date.now() - lastActivityTimestamp;
        if (timeSinceLastActivity >= autoLogoutTimeout && onTimeout) {
          onTimeout();
        }
        // Clear the timestamp after checking
        LocalCacheService.clearCloseBrowserTabTimestamp();
      }

      // Add beforeunload event listener
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [autoLogoutTimeout, handleBeforeUnload, enabled]);
};
