const GREEN_THRESHOLD = 10;
const YELLOW_THRESHOLD = -35;

export const generateTemperatureData = (minTemp, maxTemp) => {
  const data = [];
  const now = new Date();

  let lastTemp = Math.random() * (maxTemp - minTemp) + minTemp; // Start with a random temperature

  for (let i = 96; i >= 0; i--) {
    now.setMinutes(now.getMinutes() - 30);
    const formattedTime = now.toLocaleTimeString([], {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    });

    // Generate smooth temperature changes
    const fluctuation = (Math.random() * 2 - 1) * 3; // Change between -1.5°C to +1.5°C
    lastTemp = Math.min(maxTemp, Math.max(minTemp, lastTemp + fluctuation)); // Keep within bounds

    if (i > 30 && i < 38) {
      data.push({
        time: formattedTime,
        temp: parseFloat(15 + Math.random() * 0.1).toFixed(1),
        yellow: GREEN_THRESHOLD,
        red: YELLOW_THRESHOLD
      });
    } else {
      data.push({
        time: formattedTime,
        temp: parseFloat(lastTemp.toFixed(1)),
        yellow: GREEN_THRESHOLD,
        red: YELLOW_THRESHOLD
      });
    }
  }

  return data;
};
