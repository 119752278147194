import { ItemTypeReport, KioskItem } from "../../../../../../../../data/types";
import { ITEM_SCAN_STATUS_MAP } from "../../../../data/constants";
import getReportStatus from "../get-report-status";

export const handleItemScans = (
  newItems: KioskItem[],
  processedItems: Record<string, boolean>,
  itemTypeIdToReport: Record<string, ItemTypeReport>,
  customText: Record<string, string>,
  allowUnexpectedItems: boolean
) => {
  const newUnexpectedItemMap: Record<string, KioskItem> = {};
  const updatedItemTypeIds = new Set<string>();

  newItems.forEach((item) => {
    const { vid, type } = item;

    if (processedItems[vid]) {
      return;
    }

    const { id: itemTypeId = "" } = type || {};
    const report = itemTypeIdToReport[itemTypeId];

    const invalidType = !report;

    const numItems = Object.values(report?.epcToItemMap || {}).length;
    const extraItem = report && !report.epcToItemMap[vid]?.isAssociatedWithOrder && numItems >= report.quantityTotal;

    let itemIssue = "";
    if (invalidType || extraItem) {
      const typeFields = Object.entries(type).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [`itemType_${key}`]: value
        };
      }, {});

      if (invalidType) {
        itemIssue = `Invalid ${customText.itemType}`;
      } else if (extraItem) {
        itemIssue = `Extra ${customText.item}`;
      }

      newUnexpectedItemMap[vid] = {
        ...item,
        ...typeFields,
        reason: itemIssue,
        isAssociatedWithOrder: false
      };

      if (!allowUnexpectedItems) {
        return;
      }
    } else {
      report.quantityScanned++;
    }

    processedItems[vid] = true;

    if (!report) {
      return;
    }

    report.epcToItemMap[vid] = {
      ...report.epcToItemMap[vid],
      ...item,
      scanStatus: itemIssue ? ITEM_SCAN_STATUS_MAP.INVALID : ITEM_SCAN_STATUS_MAP.SCANNED,
      rowOptions: [
        {
          id: "clear",
          label: "Clear"
        }
      ]
    };

    updatedItemTypeIds.add(itemTypeId);
  });

  updatedItemTypeIds.forEach((itemTypeId) => {
    const report = itemTypeIdToReport[itemTypeId];
    const scannedItems = Object.values(report.epcToItemMap).filter((item) => {
      return item.scanStatus?.id !== ITEM_SCAN_STATUS_MAP.NOT_SCANNED.id;
    });

    report.quantityScanned = scannedItems.length;
    report.status = getReportStatus(Object.values(report.epcToItemMap), scannedItems.length, report.quantityTotal);
  });

  return newUnexpectedItemMap;
};
