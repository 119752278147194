import React from "react";
import Style from "./QuickActionButtons.module.css";
import { ReactComponent as QueueIcon } from "../../../../../../assets/icons/queue.svg";
import xemelgoStyle from "../../../../../../styles/variable";
import useAssetMapStateContext from "../../../../contexts/asset-map-state-context";
import useAssetMapConfigContext from "../../../../contexts/asset-map-config-context";

export const QuickActionButtons = () => {
  const { selectedLocationId } = useAssetMapStateContext();
  const { quickActionButtonsControl } = useAssetMapConfigContext();

  if (!quickActionButtonsControl?.length) {
    return null;
  }

  return (
    <div className={`${Style.flex_row} ${Style.quick_action_button_group}`}>
      {quickActionButtonsControl?.map((eachButton: { id: string; label: string }) => {
        return (
          <a
            key={eachButton.id}
            className={`${Style.flex_column} ${Style.quick_action_button}`}
            href={`/asset?locationId=${selectedLocationId}`}
          >
            <QueueIcon
              width={40}
              height={40}
              color={xemelgoStyle.theme.APP_BLUE}
            />
            <p className={Style.quick_action_button_title}>{eachButton.label}</p>
          </a>
        );
      })}
    </div>
  );
};
