import { AddPageInputTypeMap } from "../../../../../data/constants";
import { READ_MODE_OPTIONS } from "../../../../../hooks/use-mounted-reader";
import xemelgoStyle from "../../../../../styles/variable";
import { ACTION_OPTIONS_MAP } from "../../../data/constants";
import { DEFAULT_EPC_TABLE_HEADERS } from "../../../features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

export const DEFAULT_CONFIG = {
  actionsMap: {
    "check-out": {
      id: "check-out",
      label: "Start Checking Out",
      index: 0,
      actionType: ACTION_OPTIONS_MAP.CHECK_OUT
    }
  },
  additionalQueryAttributes: {
    item: [],
    itemType: ["image_path"],
    transferOrder: []
  },
  autoStartScanning: false,
  defaultLocationIdentifierFilterBy: "",
  edgeApiUrl: "https://pgsjd2kyk1.execute-api.us-west-2.amazonaws.com/v1",
  epcTableHeaders: DEFAULT_EPC_TABLE_HEADERS,
  hasSgtinTags: false,
  inactivityThresholdInMinutes: 30,
  itemClassFilters: [],
  itemTypeQueryAttributes: [],
  locationOptionCategory: "Department",
  printOptions: {
    enabled: false,
    printLaterEnabled: true,
    printType: "zpl",
    customTemplate: ""
  },
  readerLocationOptionCategory: "Department",
  readerOptions: {
    pauseReader: false,
    readMode: READ_MODE_OPTIONS.TAGS_TABLE,
    restartReader: false,
    startReader: false,
    startReaderOnSubmit: false,
    stopReaderOnSubmit: false,
    tagsTableApiUrl: "https://fm7jixcclb.execute-api.us-west-2.amazonaws.com/prod",
    tagsTableQueryFrequencyInSeconds: 3,
    tagsTableReadBufferMs: 2000,
    rssiFilter: {}
  },
  report: {
    additionalSubmitAttributesMap: {},
    customSubmitActionsMap: {
      consumeItem: true,
      endTrackingSession: false,
      flipHasExitState: true
    }
  },
  rowBackgroundColor: "transparent",
  showNewUPCs: true,
  sidePanelAttributes: [],
  skipSubmission: false,
  tableSections: [
    {
      attributes: [
        {
          id: "rowCount",
          label: ""
        }
      ],
      label: "#",
      size: "xsmall"
    },
    {
      attributes: [
        {
          id: "image_path",
          label: "",
          type: "image"
        }
      ],
      label: "Item Photo"
    },
    {
      attributes: [
        {
          id: "identifier",
          label: ""
        },
        {
          id: "showEpcsButton"
        }
      ],
      label: "UPC",
      size: "medium"
    },
    {
      attributes: [
        {
          id: "quantityScanned"
        }
      ],
      label: "Qty"
    }
  ],
  tableTextStyle: {
    backgroundColor: xemelgoStyle.theme.APP_WHITE,
    color: xemelgoStyle.theme.TEXT_PRIMARY,
    fontFamily: '"Avenir", sans-serif',
    fontSize: 15,
    fontWeight: 700
  },
  title: "Kiosk",
  topPanelAttributes: [
    {
      id: "location",
      isRequired: true,
      label: "Location:",
      type: "dropdownWithOptionsFromAPI"
    },
    {
      id: "readerLocation",
      isRequired: true,
      label: "Read Point:",
      type: "dropdownWithOptionsFromAPI"
    }
  ],
  upcNumberOfCharactersToTrim: 2
};

export const PICKLIST_VERIFICATION_DEFAULT_CONFIG = {
  tableSections: [
    {
      attributes: [
        {
          id: "rowCount"
        }
      ],
      label: "#",
      size: "xsmall"
    },
    {
      attributes: [
        {
          id: "image_path",
          label: "",
          type: "image"
        }
      ],
      label: "Item Photo"
    },
    {
      attributes: [
        {
          id: "name"
        },
        {
          id: "identifier",
          label: "UPC"
        },
        {
          id: "showEpcsButton"
        }
      ],
      label: "Item Description",
      size: "small"
    },
    {
      attributes: [
        {
          id: "quantityTotal"
        }
      ],
      label: "Expected"
    },
    {
      attributes: [
        {
          id: "quantityScanned"
        }
      ],
      label: "Scanned"
    },
    {
      attributes: [
        {
          id: "status"
        }
      ],
      label: "Status"
    }
  ],
  topPanelAttributes: [
    {
      id: "stage",
      isReadOnly: true,
      label: "Stage:"
    },
    {
      id: "transferOrderIdentifiersString",
      isReadOnly: true,
      label: "Tracking #:"
    },
    {
      id: "readerLocation",
      isRequired: true,
      label: "Read Point:",
      type: AddPageInputTypeMap.SEARCH_DROP_DOWN
    }
  ]
};

export const ORDER_CREATION_DEFAULT_CONFIG = {
  topPanelAttributes: [
    {
      id: "transferOrderIdentifiersString",
      isReadOnly: true,
      label: "Tracking #:"
    },
    {
      id: "readerLocation",
      isRequired: true,
      label: "Read Point:",
      type: AddPageInputTypeMap.SEARCH_DROP_DOWN
    }
  ]
};

export const ACTION_DEFAULT_CONFIGS = {
  [ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION]: PICKLIST_VERIFICATION_DEFAULT_CONFIG,
  [ACTION_OPTIONS_MAP.ORDER_CREATION]: ORDER_CREATION_DEFAULT_CONFIG
};
