import React, { useMemo, useRef, useState, useEffect } from "react";
import { Event as CalendarIcon } from "@material-ui/icons";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import Style from "./DateRangePopupButton.module.css";
import DateRangePicker, { ALL_TIME_ID, ALL_TIME_LABEL, QUICK_FILTER_OPTIONS } from "../data-range-picker";
import { getFormattedDate, TIME_IN_MS } from "../../common/Utilities";

interface QuickFilterType {
  id: string;
  label: string;
  value: number;
}

interface DateRangePopupButtonProps {
  onConfirm?: (startDate: Date | null, endDate: Date | null) => void;
  quickFilterOptions: QuickFilterType[];
  timeFormat?: string;
  defaultRouteTimeframeMs?: number;
  maxDuration?: number;
}

export const DateRangePopupButton: React.FC<DateRangePopupButtonProps> = ({
  onConfirm = () => {},
  quickFilterOptions = QUICK_FILTER_OPTIONS,
  timeFormat = "MMM D, YYYY h:mm A",
  defaultRouteTimeframeMs = TIME_IN_MS.WEEKS,
  maxDuration
}) => {
  const [nowTimestamp, setNowTimestamp] = useState(Date.now());
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [alignRight, setAlignRight] = useState(false); // Tracks whether to align right
  const [isAllTimeSelected, setIsAllTimeSelected] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date(nowTimestamp - defaultRouteTimeframeMs));
  const [endDate, setEndDate] = useState<Date>(new Date(nowTimestamp));

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    setIsPopupOpen(false);
  });

  const timeRangeText = useMemo(() => {
    if (isAllTimeSelected) {
      return (
        quickFilterOptions.find((option) => {
          return option.id === ALL_TIME_ID;
        })?.label || ALL_TIME_LABEL
      );
    }

    const isEndDateNow = endDate.getTime() >= nowTimestamp;
    const timeDifference = endDate.getTime() - startDate.getTime();

    const quickSelectOption = quickFilterOptions.find((option) => {
      return option.value === timeDifference;
    });

    return isEndDateNow && quickSelectOption
      ? quickSelectOption.label
      : `${getFormattedDate(startDate, timeFormat)} - ${getFormattedDate(endDate, timeFormat)}`;
  }, [startDate, endDate, isAllTimeSelected]);

  useEffect(() => {
    setNowTimestamp(Date.now());
    if (isPopupOpen && dropdownRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      // Check if dropdown overflows the right edge of the browser window
      if (buttonRect.left + dropdownRect.width > window.innerWidth) {
        setAlignRight(true); // Align dropdown to the right
      } else {
        setAlignRight(false); // Align dropdown to the left
      }
    }
  }, [isPopupOpen]);

  useEffect(() => {
    if (!isPopupOpen) {
      onConfirm(isAllTimeSelected ? null : startDate, isAllTimeSelected ? null : endDate);
    }
  }, [startDate, endDate, isPopupOpen, isAllTimeSelected]);

  return (
    <div
      data-cy="date-range-popup-button"
      className={Style.container}
      ref={containerRef}
    >
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setIsPopupOpen(!isPopupOpen);
        }}
        ref={buttonRef}
      >
        {`Time Range: ${timeRangeText}`}
        <CalendarIcon className={Style.icon} />
      </button>
      {isPopupOpen && (
        <div
          ref={dropdownRef}
          className={`${Style.popup_container} ${alignRight ? Style.alignRight : ""}`}
        >
          <DateRangePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onClose={() => {
              return setIsPopupOpen(false);
            }}
            onConfirm={(newIsAllTimeSelected, newStartDate, newEndDate) => {
              setIsAllTimeSelected(newIsAllTimeSelected);
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              setIsPopupOpen(false);
            }}
            defaultDateRangeMs={defaultRouteTimeframeMs}
            allTimeSelected={isAllTimeSelected}
            quickFilterOptions={quickFilterOptions}
            maxDuration={maxDuration}
          />
        </div>
      )}
    </div>
  );
};
