import { ITEM_CLASSES } from "../../../../../../../../../../data/constants";
import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ItemTypeReport, KioskItem, OnboardedKioskItem } from "../../../../../../../../data/types";
import { queryItemsFromBasicTags } from "../../../../../../../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";
import { ITEM_SCAN_STATUS_MAP, ITEM_TYPE_STATUS_MAP } from "../../../../data/constants";

export type ProcessedItemTypeReport = ItemTypeReport & {
  id: string;
  identifier: string;
  itemTypeId: string;
  epcToItemMap: Record<string, OnboardedKioskItem>;
};

export const onboardItems = async (
  itemTypeReports: ItemTypeReport[],
  itemClass: string,
  container?: { vid: string; identifier: string },
  containerTypeIdentifier: string = "Pallet"
): Promise<{
  itemTypeEntries: ProcessedItemTypeReport[];
  containerEntry?: ProcessedItemTypeReport;
}> => {
  const inventoryClient = XemelgoService.getClient().getInventoryClient();

  let containerEntryIndex = itemTypeReports.findIndex((itemTypeReport) => {
    return itemTypeReport.identifier === containerTypeIdentifier;
  });
  if (container) {
    const { vid: containerVid, identifier: containerIdentifier } = container;

    const containerItem = {
      id: "",
      vid: containerVid,
      identifier: containerIdentifier,
      scanStatus: ITEM_SCAN_STATUS_MAP.SCANNED,
      type: { id: "", identifier: containerTypeIdentifier },
      class: ITEM_CLASSES.Container
    };

    // If there are already contains in this transfer order, add to that item entry. Otherwise, create a new item entry
    if (containerEntryIndex === -1) {
      itemTypeReports.push({
        id: "",
        identifier: containerTypeIdentifier,
        quantityScanned: 1,
        quantityTotal: 1,
        itemTypeId: "",
        epcToItemMap: {
          [containerVid]: containerItem
        },
        status: ITEM_TYPE_STATUS_MAP.complete
      });
      containerEntryIndex = itemTypeReports.length - 1;
    } else {
      itemTypeReports[containerEntryIndex].quantityScanned += 1;
      itemTypeReports[containerEntryIndex].epcToItemMap[containerVid] = containerItem;
    }
  }

  const { epcToCreatedItemAcc: epcToCreatedItem, itemsToQueryAcc: itemsToQuery } = itemTypeReports.reduce(
    (
      acc: {
        epcToCreatedItemAcc: Record<string, OnboardedKioskItem>;
        itemsToQueryAcc: KioskItem[];
      },
      itemTypeReport
    ) => {
      const { epcToCreatedItemAcc, itemsToQueryAcc } = acc;
      const { epcToItemMap } = itemTypeReport;

      Object.entries(epcToItemMap).forEach(([epc, item]) => {
        if (item.id && item.sensorProfileId && item.type.id) {
          // item was retrieved on MainPage when scanning tags
          epcToCreatedItemAcc[epc] = {
            ...item,
            itemId: item.id,
            sensorProfileId: item.sensorProfileId,
            itemTypeId: item.type.id
          };
        } else {
          itemsToQueryAcc.push(item);
        }
      });

      return { epcToCreatedItemAcc, itemsToQueryAcc };
    },
    {
      epcToCreatedItemAcc: {},
      itemsToQueryAcc: []
    }
  );

  if (itemsToQuery.length) {
    const existingItems = Object.values(
      await queryItemsFromBasicTags(itemsToQuery, {}, [itemClass, ITEM_CLASSES.Container])
    );

    existingItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  const payload = itemTypeReports.reduce((acc: Record<string, string>[], itemTypeReport) => {
    const { identifier: itemTypeIdentifier, epcToItemMap } = itemTypeReport;

    Object.entries(epcToItemMap).forEach(([epc, item]) => {
      if (!epcToCreatedItem[epc]) {
        acc.push({
          item_number: itemTypeIdentifier,
          item_identifier: item.identifier,
          tracker_serial: epc,
          class: item.class || itemClass
        });
      }
    });

    return acc;
  }, []);

  if (payload.length) {
    const createdItemVids = (await inventoryClient.createItemSet(payload)).createItemSet.map((vid) => {
      return {
        vid
      };
    });
    const createdItems = Object.values(
      await queryItemsFromBasicTags(createdItemVids, {}, [itemClass, ITEM_CLASSES.Container])
    );

    createdItems.forEach((item) => {
      epcToCreatedItem[item.vid] = item;
    });
  }

  const itemTypeEntries = formatItemTypeReports(itemTypeReports, epcToCreatedItem);

  if (containerEntryIndex !== -1) {
    const containerEntry = itemTypeEntries.splice(containerEntryIndex, 1)[0];

    return {
      containerEntry,
      itemTypeEntries
    };
  }

  return {
    itemTypeEntries
  };
};

const formatItemTypeReports = (
  itemTypeReports: ItemTypeReport[],
  epcToCreatedItem: Record<string, OnboardedKioskItem>
) => {
  return itemTypeReports.reduce((acc: ProcessedItemTypeReport[], itemTypeReport) => {
    let { id: itemTypeId } = itemTypeReport;
    const { epcToItemMap } = itemTypeReport;

    const newEpcToItemMap = Object.entries(epcToItemMap)
      .map(([epc, item]) => {
        return {
          ...item,
          ...epcToCreatedItem[epc]
        };
      })
      .filter((item) => {
        // Filter out items that are associated with the order but not scanned (for picklist verification)
        return item.scanStatus.id === ITEM_SCAN_STATUS_MAP.SCANNED.id;
      })
      .reduce((itemAcc: Record<string, OnboardedKioskItem>, item) => {
        itemAcc[item.vid] = item;
        return itemAcc;
      }, {});

    // If a new type was created through createItemSet, use that type id
    if (!itemTypeId && Object.values(newEpcToItemMap).length) {
      itemTypeId = Object.values(newEpcToItemMap)[0].itemTypeId as string;
    }

    acc.push({
      ...itemTypeReport,
      itemTypeId,
      epcToItemMap: newEpcToItemMap
    });

    return acc;
  }, []);
};
