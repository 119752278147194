import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight, ExpandMore, ExpandLess } from "@material-ui/icons";
import ListTable from "../ListTable/ListTable";
import Style from "./PaginatedList.module.css";
import useOnClickOutside from "../../hooks/use-on-click-outside";

const PaginatedList = ({
  data,
  numItemsPerPage,
  showRowsPerPage,
  pagerSizeOptions,
  paginatorClassName,
  paginatorFontWeight,
  paginatorLocation,
  renderPaginatorLeftContent,
  ...restProps
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numItemsPerPage);
  const [pagerSizeSelectorOpen, setPagerSizeSelectorOpen] = useState(false);

  const { pagedItemList, pageStart, pageEnd } = useMemo(() => {
    const newPageStart = pageNumber * itemsPerPage - itemsPerPage;
    const newPageEnd = pageNumber * itemsPerPage < data.length ? pageNumber * itemsPerPage : data.length;
    return { pagedItemList: data.slice(newPageStart, newPageEnd), pageStart: newPageStart, pageEnd: newPageEnd };
  }, [data, pageNumber, itemsPerPage]);

  // reset page to 1 after filter
  const [prevDataLength, setPrevDataLength] = useState(0);
  useEffect(() => {
    if (data.length !== prevDataLength) {
      setPrevDataLength(data.length);
      setPageNumber(1);
    }
  }, [data]);

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber >= Math.ceil(data.length / itemsPerPage);

  const leftButton = isFirstPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;
  const rightButton = isLastPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;

  const PagerSizeSelector = ({ fontWeight }) => {
    const pagerSizeSelectorButtonRef = useRef(null);

    useOnClickOutside(pagerSizeSelectorButtonRef, () => {
      if (pagerSizeSelectorOpen) {
        setPagerSizeSelectorOpen(false);
      }
    });

    return (
      <div
        ref={pagerSizeSelectorButtonRef}
        className={`${Style.flex_row} ${Style.table_pager_size_container}`}
        style={{ fontWeight }}
      >
        <p className={Style.table_pager_size_text}>Rows per page:</p>
        <div
          className={`${Style.table_pager_size_dropdown_button}`}
          onClick={() => {
            setPagerSizeSelectorOpen((currentValue) => {
              return !currentValue;
            });
          }}
        >
          <p>{itemsPerPage}</p>
          {pagerSizeSelectorOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
        {pagerSizeSelectorOpen && (
          <div className={Style.table_pager_size_dropdown_menu}>
            {pagerSizeOptions.map((option) => {
              return (
                <button
                  key={option}
                  type="button"
                  className={`${Style.table_pager_size_dropdown_item} ${
                    option === itemsPerPage && Style.table_pager_size_dropdown_item_active
                  }`}
                  onClick={() => {
                    setItemsPerPage(option);
                    setPagerSizeSelectorOpen(false);
                  }}
                >
                  {option}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const Paginator = ({ fontWeight }) => {
    return (
      <div
        className={`${Style.flex_row} ${Style.table_pager}`}
        style={{ fontWeight }}
      >
        <button
          data-cy="paginator-prev"
          type="button"
          className={leftButton}
          onClick={() => {
            if (!isFirstPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
          disabled={isFirstPage}
        >
          <ChevronLeft />
        </button>
        <p
          className={Style.table_pager_number}
          style={{ fontWeight }}
        >
          {`${data.length ? pageStart + 1 : 0} - ${pageEnd} of ${data.length}`}
        </p>
        <button
          data-cy="paginator-next"
          type="button"
          className={rightButton}
          onClick={() => {
            if (!isLastPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          disabled={isLastPage}
        >
          <ChevronRight />
        </button>
      </div>
    );
  };

  const renderPaginator = () => {
    return (
      <div
        data-cy="paginator"
        className={Style.flex_row}
      >
        <div className={Style.paginator_left_content_container}>
          {renderPaginatorLeftContent && renderPaginatorLeftContent()}
        </div>
        <div className={Style.paginator_container} />
        {showRowsPerPage && <PagerSizeSelector fontWeight={paginatorFontWeight} />}
        <Paginator fontWeight={paginatorFontWeight} />
      </div>
    );
  };

  return (
    <div className={`${paginatorLocation === "top" ? Style.flex_column : Style.flex_column_reverse}`}>
      {paginatorLocation !== "none" && renderPaginator()}
      <ListTable
        data={pagedItemList}
        {...restProps}
      />
    </div>
  );
};

PaginatedList.defaultProps = {
  data: [],
  numItemsPerPage: 10,
  paginatorLocation: "bottom",
  showRowsPerPage: false,
  paginatorClassName: "",
  paginatorFontWeight: "normal",
  pagerSizeOptions: [10, 20, 30, 40, 50]
};

PaginatedList.propTypes = {
  paginatorLocation: PropTypes.oneOf(["top", "bottom", "none"]),
  data: PropTypes.array,
  numItemsPerPage: PropTypes.number,
  showRowsPerPage: PropTypes.bool,
  paginatorClassName: PropTypes.string,
  paginatorFontWeight: PropTypes.string,
  pagerSizeOptions: PropTypes.arrayOf(PropTypes.number),
  renderPaginatorLeftContent: PropTypes.func
};

export default PaginatedList;
