import React, { Component, Fragment } from "react";
import Switch from "react-switch";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import "./RowWithActionComponent.css";
import { getFormattedDate } from "common/Utilities";
import xemelgoStyle from "../../styles/variable";
import ValueUnitInput from "../value-unit-input";

const RevertEvent = "revert";
const DeleteEvent = "delete";
const UpdateSuccessEvent = "approve";
const ContentChangedEvent = "contentChanged";
const ToggleEditEvent = "editToggled";
const SubscrptionChangedEvent = "subscriptionChanged";

export default class RowWithActionComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleEdit = this.toggleEdit.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.revert = this.revert.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.signalEvent = this.signalEvent.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleDropdownOptionsFn = this.handleDropdownOptionsFn.bind(this);

    /**
     * Register event callback function, this supports use case of parent component calling child component to undo the editing.
     */
    if (props.registerEventCallbacksFn) {
      props.registerEventCallbacksFn(RevertEvent, props.id, this.revert);
      props.registerEventCallbacksFn(UpdateSuccessEvent, props.id, this.updateSuccess);
    }

    this.state = {
      readOnly: props.readOnly,
      markForDelete: false,
      data: { ...props.data },
      id: props.id,
      location: null
    };
  }

  componentWillUnmount() {
    if (this.unregisterEventCallbacksFn) {
      this.unregisterEventCallbacksFn(RevertEvent, this.props.id);
      this.unregisterEventCallbacksFn(UpdateSuccessEvent, this.props.id);
    }
  }

  signalEvent(event, payload) {
    if (this.props.signalEventFn) {
      this.props.signalEventFn(event, this.state.id, payload);
    }
  }

  /**
   * Toggle edit signal 'revert' action if user toggle 'off' the edit mode. This should return row value to default
   */
  toggleEdit() {
    const isCurrentReadOnly = this.state.readOnly;

    if (!isCurrentReadOnly) {
      this.signalEvent(RevertEvent);
    }

    const data = { ...this.state.data };
    data._event = "pending_edit";
    this.signalEvent(ToggleEditEvent, {
      changed: isCurrentReadOnly,
      data
    });

    this.setState({ readOnly: !isCurrentReadOnly });
  }

  toggleDelete() {
    const markedForDelete = !this.state.markForDelete;

    if (markedForDelete) {
      this.signalEvent(RevertEvent);
    }

    const data = { ...this.state.data };
    data._event = "pending_delete";
    this.signalEvent(DeleteEvent, {
      changed: markedForDelete,
      data
    });

    if (this.props.handleOptionsResetFn) {
      this.props.handleOptionsResetFn("unselect-one", data);
    }

    this.setState({ markForDelete: markedForDelete });
  }

  updateSuccess() {
    this.setState({
      readOnly: true
    });
  }

  /**
   * This function is register as callback for parent component.
   *  This gives parent component a way to call child component to undo edit.
   *  This supports a use case for parent component to signal children to undo all editing.
   */
  revert() {
    this.setState({
      data: { ...this.props.data },
      readOnly: true,
      markForDelete: false
    });
  }

  handleEdit(headerId, newValue, callBySwitch, clearInputFn) {
    // indicate value has not been changed
    const data = { ...this.state.data };
    data[headerId] = newValue;
    data._event = data._pendingNew ? "pending_new" : "pending_update";

    let signalPayload = {
      data,
      changed: true
    };

    if (this.props.handleAutoFillFn) {
      signalPayload = this.props.handleAutoFillFn(headerId, newValue, data);
    } else {
      const defaultValue = this.props.data[headerId] && this.props.data[headerId].toString();
      if (defaultValue === newValue && clearInputFn && !clearInputFn(data, newValue)) {
        signalPayload.changed = false;
      }
    }
    if (!callBySwitch) {
      this.signalEvent(ContentChangedEvent, signalPayload);
      this.setState({ data });
    } else {
      this.signalEvent(SubscrptionChangedEvent, newValue);
    }
  }

  handleDropdownOptionsFn(event, data, headerId) {
    const selectedValue = event.target.value;
    data.forEach((item) => {
      if (item.objectTypeName === selectedValue) {
        item._selected = true;
      }
      if (item.objectTypeName === this.state.location) {
        item._selected = false;
      }
    });
    this.setState({ location: selectedValue });
    this.handleEdit(headerId, selectedValue);
  }

  clearInput(data, newValue) {
    return data._pendingNew && newValue === "";
  }

  render() {
    let className = "row-with-action-component";
    const additionClassName = this.props.className;

    if (additionClassName) {
      className += ` ${additionClassName}`;
    }

    const hideEdit = !this.props.enabledEditAction || this.state.markForDelete;
    const isEditMode = !this.state.readOnly;
    const hideDelete = this.props.disableDeleteAction;
    const timeZone = getFormattedDate(Date.now(), "Z");

    return (
      <>
        <tr className={`${className} ${this.state.markForDelete ? "pending-delete" : ""}`}>
          {this.props.headers.map((header, headerIndex) => {
            let pendingNew = false;
            if (this.state.data._pendingNew) {
              pendingNew = true;
            }

            const { input, data, display, modifiable, unit, shouldRenderHeader } = {
              ...header.cell
            };
            if (!shouldRenderHeader || shouldRenderHeader(header, this.state.data)) {
              let componentName = display;
              if (isEditMode && (modifiable || pendingNew)) {
                componentName = input;
              }

              if (componentName === "dropdown") {
                return (
                  <td
                    className="editable-table-data-row-cell"
                    key={`data-cell-${headerIndex}`}
                  >
                    <select
                      disabled={isEditMode && !modifiable && !pendingNew}
                      className={this.props.inputError ? (this.props.inputError[header.id] ? "input-error" : "") : ""}
                      value={this.state.data[header.id] || ""}
                      onChange={(event) => {
                        this.handleDropdownOptionsFn(event, data, header.id);
                      }}
                    >
                      <option
                        disabled
                        value=""
                      >
                        -- Select --
                      </option>
                      {data.map((item) => {
                        return (
                          <option
                            disabled={item._selected}
                            key={item.objectTypeName}
                          >
                            {item.objectTypeName}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                );
              }

              if (componentName === "text") {
                const inputComponentClassName = this.props.inputError
                  ? this.props.inputError[header.id]
                    ? "input-error"
                    : ""
                  : isEditMode && modifiable
                  ? "input-on-edit-mode"
                  : "";
                const cellComponent =
                  !isEditMode || !modifiable ? (
                    <div className="text-cell">
                      {this.state.data[header.id] &&
                        this.state.data[header.id] + (unit && !isEditMode ? ` ${unit}` : "")}
                    </div>
                  ) : (
                    <input
                      type="text"
                      className={inputComponentClassName}
                      value={
                        this.state.data[header.id] &&
                        this.state.data[header.id] + (unit && !isEditMode ? ` ${unit}` : "")
                      }
                      disabled={!isEditMode || !modifiable}
                      onChange={(event) => {
                        return this.handleEdit(header.id, event.target.value);
                      }}
                    />
                  );

                return (
                  <td
                    className="editable-table-data-row-cell"
                    key={`data-cell-${headerIndex}`}
                  >
                    {cellComponent}
                  </td>
                );
              }

              if (componentName === "switch") {
                return (
                  <td
                    className="editable-table-data-row-cell"
                    key={`data-cell-${headerIndex}`}
                  >
                    <Switch
                      onChange={(event) => {
                        return this.handleEdit(header.id, event, true);
                      }}
                      checked={!!this.state.data[header.id]}
                      id={this.state.data[header.id].toString()}
                      className="react_switch"
                      width={40}
                      height={21}
                      disabled={isEditMode}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={xemelgoStyle.theme.STATUS_GREEN}
                    />
                  </td>
                );
              }

              if (componentName === "time") {
                return (
                  <td
                    className="editable-table-data-row-cell"
                    key={`data-cell-${headerIndex}`}
                  >
                    <input
                      onChange={(event) => {
                        return this.handleEdit(header.id, event.target.value, null, this.clearInput);
                      }}
                      id={this.state.data[header.id].toString()}
                      type="time"
                      value={this.state.data[header.id]}
                    />
                    {` ${timeZone}`}
                  </td>
                );
              }

              if (componentName === "textWithUnit") {
                const hasError = this.props.inputError && this.props.inputError[header.id];
                const { options, id: valueId, unitId } = header;
                const data = this.state.data;

                const cellComponent =
                  !isEditMode || !modifiable ? (
                    <div className="text-cell">{`${data[valueId] || ""} ${data[unitId]}`}</div>
                  ) : (
                    <ValueUnitInput
                      initialValue={data[valueId]}
                      initialUnit={data[unitId]}
                      units={options}
                      error={hasError}
                      onValueChange={(value) => {
                        this.handleEdit(valueId, value);
                      }}
                      onUnitChange={(value) => {
                        this.handleEdit(unitId, value);
                      }}
                    />
                  );

                return (
                  <td
                    className="editable-table-data-row-cell"
                    key={`data-cell-${headerIndex}`}
                  >
                    {cellComponent}
                  </td>
                );
              }

              return <td />;
            }
          })}
          <td className="icons-block">
            <EditIcon
              onClick={this.toggleEdit}
              className={`clickable ${hideEdit ? "hidden" : ""}`}
              style={{
                color: xemelgoStyle.theme.APP_BLUE,
                marginRight: ".7em",
                marginTop: ".15em"
              }}
            />
            <DeleteIcon
              onClick={this.toggleDelete}
              className={`clickable ${hideDelete ? "hidden" : ""}`}
              style={{
                color: xemelgoStyle.theme.STATUS_RED,
                marginTop: ".15em"
              }}
            />
          </td>
        </tr>
      </>
    );
  }
}
