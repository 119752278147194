import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { READ_MODE_OPTIONS, READ_STATUS_OPTIONS } from "./data/constants";
import { getTagsSinceTimestamp } from "../../services/get-recent-tags-service";
import callEdgeOperation, { EDGE_OPERATION_MAP } from "../../utils/call-edge-operation";
import useAuthenticationContext from "../../context/authentication-context";
import useMixpanelContext from "../../context/mixpanel-context";
import { IoTTagMessage, ReaderOptions, ScannedTag, TagType } from "./data/types";
import { decodeSGTIN96Tag, SGTINInfo } from "../../utils/decode-sgtin-96-tag";
import filterIncomingTags from "./utils/filter-incoming-tags";
import useIoTTopic from "./hooks/use-iot-topic";

export const useMountedReader = (detectorVid: string, edgeApiUrl: string, readerOptions: ReaderOptions = {}) => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const { cognitoUser } = useAuthenticationContext();
  const tagMapRef = useRef<Record<string, ScannedTag>>({});
  const tagsTableIntervalIdRef = useRef<NodeJS.Timeout>();
  const detectorSerialToResetRef = useRef<string>();
  const [readerStatus, setReaderStatus] = useState(READ_STATUS_OPTIONS.NOT_READING);

  const parseDetectorVid = (vid: string) => {
    const splitVid = vid?.split("#") || [];
    return {
      detectorSerial: splitVid[0] || "",
      antennaId: splitVid[1] || ""
    };
  };

  // To allow cypress tests to set the tag map
  if (typeof window !== "undefined" && (window as any).Cypress) {
    // @ts-ignore
    window.setMountedReaderTagMap = (tagMap: Record<string, ScannedTag>) => {
      tagMapRef.current = tagMap;
    };
  }

  const { detectorSerial, antennaId } = useMemo(() => {
    return parseDetectorVid(detectorVid);
  }, [detectorVid]);

  const processTag = useCallback(
    (tag: TagType) => {
      const filteredTags = filterIncomingTags([tag], antennaId, readerOptions.rssiFilter);
      filteredTags.forEach((filteredTag) => {
        const { Name: vid = "", analytic } = filteredTag;
        let sgtinInfo: SGTINInfo | undefined;
        try {
          sgtinInfo = decodeSGTIN96Tag(vid);
        } catch (error) {
          sgtinInfo = undefined;
        }

        tagMapRef.current[vid] = {
          vid: vid.toUpperCase(),
          analyticValue: analytic?.value,
          sgtinInfo
        };
      });
    },
    [antennaId, readerOptions.rssiFilter]
  );

  const onReadIoTMessage = useCallback(
    (data: IoTTagMessage) => {
      const { tags } = data.value;
      tags.forEach(processTag);
    },
    [processTag]
  );

  const iotTopic = useMemo(() => {
    return readerOptions.readMode === READ_MODE_OPTIONS.IOT_TOPIC && detectorSerial
      ? `devices/${detectorSerial}/tags`
      : undefined;
  }, [readerOptions.readMode, detectorSerial]);

  const { isConnected: isIoTConnected } = useIoTTopic(onReadIoTMessage, iotTopic);

  const isConnected = useMemo(() => {
    return readerOptions.readMode === READ_MODE_OPTIONS.IOT_TOPIC ? isIoTConnected : true;
  }, [isIoTConnected, readerOptions.readMode]);

  const tenantId: string = useMemo(() => {
    return cognitoUser?.attributes?.["custom:tenantId"];
  }, [cognitoUser]);

  const edgeOperationParams = useMemo(() => {
    return {
      edgeApiUrl,
      deviceId: detectorSerial,
      tenantId,
      sendLogEvent: sendMixPanelEvent
    };
  }, [readerOptions, detectorSerial, tenantId]);

  useEffect(() => {
    return () => {
      resetMountedReader();
    };
  }, [detectorSerial, tenantId]);

  const subscribeToMountedReader = () => {
    if (!detectorSerial) {
      return;
    }

    switch (readerOptions.readMode) {
      case READ_MODE_OPTIONS.TAGS_TABLE:
        subscribeToTagsTable();
        break;
      case READ_MODE_OPTIONS.IOT_TOPIC:
        // These are handled by their respective hooks
        break;
      default:
    }
  };

  const subscribeToTagsTable = async () => {
    const {
      tagsTableQueryFrequencyInSeconds = 3,
      tagsTableApiUrl,
      tagsTableReadBufferMs = 2000,
      rssiFilter
    } = readerOptions;
    if (!tagsTableApiUrl) {
      throw new Error("Missing API URL is for reading tags.");
    }

    let lastQueryTimestamp = Date.now();

    tagMapRef.current = {};

    const intervalId = setInterval(async () => {
      const tags = await getTagsSinceTimestamp(
        tagsTableApiUrl,
        detectorSerial,
        lastQueryTimestamp - tagsTableReadBufferMs
      );

      const filteredTags = filterIncomingTags(tags, antennaId, rssiFilter);

      filteredTags.forEach((tag) => {
        const { Name: vid = "", analytic } = tag;

        const sgtinInfo = {
          epc: vid,
          upc: analytic?.upc || "",
          filter: "",
          companyPrefix: "",
          itemReference: "",
          serialNumber: analytic?.serial || ""
        };

        tagMapRef.current[vid] = {
          vid: vid.toUpperCase(),
          analyticValue: analytic?.value,
          sgtinInfo
        };
      });
      lastQueryTimestamp = Date.now();
    }, 1000 * tagsTableQueryFrequencyInSeconds);

    if (tagsTableIntervalIdRef.current !== undefined) {
      clearInterval(tagsTableIntervalIdRef.current);
    }
    tagsTableIntervalIdRef.current = intervalId;
  };

  const getReaderTagMap = (clearTags = false) => {
    if (!detectorVid) {
      return {};
    }

    const tagsMap = tagMapRef.current || {};

    if (clearTags) {
      tagMapRef.current = {};
    }

    return tagsMap;
  };

  const unsubscribeFromMountedReader = () => {
    if (tagsTableIntervalIdRef.current) {
      clearInterval(tagsTableIntervalIdRef.current);
      tagsTableIntervalIdRef.current = undefined;
    }
  };

  const startMountedReader = async (abortSignal: AbortSignal) => {
    detectorSerialToResetRef.current = detectorSerial;
    setReaderStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader();

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          operation: EDGE_OPERATION_MAP.DISABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReader) {
      promises.push(callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal }));
    } else if (readerOptions.restartReader) {
      promises.push(
        callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal }).then(() => {
          return callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
        })
      );
    }

    await Promise.all(promises);
    setReaderStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const pauseMountedReader = async (abortSignal: AbortSignal) => {
    setReaderStatus(READ_STATUS_OPTIONS.PAUSING);
    unsubscribeFromMountedReader();

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.STOP, abortSignal });
    }

    setReaderStatus(READ_STATUS_OPTIONS.PAUSED);
  };

  const resumeMountedReader = async (abortSignal: AbortSignal) => {
    setReaderStatus(READ_STATUS_OPTIONS.STARTING);
    subscribeToMountedReader();

    if (readerOptions.pauseReader) {
      await callEdgeOperation({ ...edgeOperationParams, operation: EDGE_OPERATION_MAP.START, abortSignal });
    }

    setReaderStatus(READ_STATUS_OPTIONS.IN_PROGRESS);
  };

  const stopMountedReader = async (abortSignal: AbortSignal) => {
    await resetMountedReader(abortSignal);
  };

  const resetMountedReader = async (abortSignal?: AbortSignal) => {
    if (!detectorSerialToResetRef.current) {
      return;
    }
    const detectorSerialToReset = detectorSerialToResetRef.current;
    detectorSerialToResetRef.current = "";

    setReaderStatus(READ_STATUS_OPTIONS.STOPPING);
    unsubscribeFromMountedReader();

    const promises = [];

    if (readerOptions.disableIngestion) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.ENABLE_INGESTION_RULE,
          maxRetries: 3,
          abortSignal
        })
      );
    }

    if (readerOptions.startReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.START,
          abortSignal
        })
      );
    } else if (readerOptions.stopReaderOnSubmit) {
      promises.push(
        callEdgeOperation({
          ...edgeOperationParams,
          detectorSerial: detectorSerialToReset,
          operation: EDGE_OPERATION_MAP.STOP,
          abortSignal
        })
      );
    }

    await Promise.all(promises);

    setReaderStatus(READ_STATUS_OPTIONS.NOT_READING);
  };

  return {
    startMountedReader,
    pauseMountedReader,
    resumeMountedReader,
    stopMountedReader,
    getReaderTagMap,
    readerStatus,
    isConnected
  };
};
