import React from "react";
import Style from "./EpcTable.module.css";
import PaginatedListTableWithTypes, {
  Header
} from "../../../../../../../../../../components/paginated-list-table-with-types";
import { ITEM_CATEGORY_TO_ROUTE_NAME } from "../../../../../../../../../../routes/data/constants";
import { DEFAULT_EPC_TABLE_HEADERS } from "../../../../../transfer-order-actions/data/constants";
import { KioskItem } from "../../../../../../../../data/types";

interface EpcTableProps {
  headers: Header[];
  items: KioskItem[];
  onClearItem: (vid: string) => void;
}

export const EpcTable = ({
  headers = DEFAULT_EPC_TABLE_HEADERS,
  items = [],
  onClearItem = () => {}
}: EpcTableProps) => {
  const augmentedItems = items.map((item) => {
    return {
      ...item,
      status: [item.scanStatus],
      statusText: item.scanStatus?.sortIndex
    };
  });

  return (
    <div
      className={Style.table_container}
      data-cy="epc-table"
    >
      <PaginatedListTableWithTypes
        headers={headers}
        getTitleURLCallbackFn={(data) => {
          const { id, category } = data;

          if (id && typeof category === "string" && ITEM_CATEGORY_TO_ROUTE_NAME[category]) {
            return `${ITEM_CATEGORY_TO_ROUTE_NAME[category]}/item/detail?itemId=${id}`;
          }

          return "";
        }}
        headerRowContainerClassName={Style.header_row}
        dataList={augmentedItems}
        itemRowContainerClassName={Style.item_row}
        paginatorLocation="top"
        paginatorClassName={Style.paginator}
        showRowOptions
        onRowOptionClick={(option, item) => {
          if (option.id === "clear" && typeof item.vid === "string") {
            onClearItem(item.vid);
          }
        }}
        numItemsPerPage={5}
        openLinkInNewTab
        sortIdReplacementMap={{
          status: "statusText"
        }}
      />
    </div>
  );
};

EpcTable.defaultProps = {};
