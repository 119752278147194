import React, { useMemo } from "react";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";
import useAssetMapConfigContext from "../../contexts/asset-map-config-context";
import LocationSelectionContent from "../../components/location-selection-content";
import Style from "../../AssetMap.module.css";

export const SiteSelectionContent = () => {
  const { mapRef, setSiteLocation, setSelectedLocationId, locationMap, locationToCounts } = useAssetMapStateContext();

  const { mapConfig, switchSiteControl, siteSelectionTableOptions } = useAssetMapConfigContext();

  const siteLocations = useMemo(() => {
    const children = Object.keys(locationMap)
      .filter((locationId) => {
        return switchSiteControl?.locationCategory
          ? locationMap[locationId].category === switchSiteControl.locationCategory
          : locationMap[locationId.category];
      })
      .map((locationId) => {
        return {
          ...locationMap[locationId],
          ...(locationToCounts[locationId] || {})
        };
      });

    return children;
  }, [locationMap, switchSiteControl, locationToCounts]);

  return (
    <>
      <div className={Style.information_header}>
        <div className={`${Style.flex_row} ${Style.information_title_container}`}>
          <p className={Style.information_title}>
            {`Select a ${(switchSiteControl?.locationCategory || "Site").toLowerCase()} to view`}
          </p>
        </div>
      </div>
      <LocationSelectionContent
        mapRef={mapRef}
        headers={siteSelectionTableOptions.headers}
        pageSize={siteSelectionTableOptions.pageSize}
        locations={siteLocations}
        onLocationClick={async (location) => {
          setSelectedLocationId(location.id);

          const feature = mapConfig.markersGeojson.features.find((eachFeature) => {
            return eachFeature.properties.id === location.id;
          });

          if (feature) {
            mapRef.current.selectMarker(location.id, true);
            const { properties, geometry } = feature;
            setSiteLocation(location.id, {
              ...(properties?.viewStates || "{}"),
              zoom: properties?.zoom,
              center: geometry.coordinates
            });
          } else {
            setSiteLocation(location.id, {});
          }
        }}
      />
    </>
  );
};
