import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { LOCATION_SORT_OPTIONS } from "../../data/constants";
import { AssetTrackPageHeader, MultiSelectOption } from "./data/types";

type FeatureButton = {
  id: string;
  label?: string;
  roles: string[];
  disabled?: boolean;
};

type AssetTrackPageConfigContextType = {
  featureButtonsControl: FeatureButton[];
  ignoredItemStates: string[];
  initialURLParameters: string;
  isLoading: boolean;
  listTableOptions: Record<
    string,
    {
      headers: AssetTrackPageHeader[];
      pageSize: number;
    }
  >;
  locationCategories: string[];
  locationMetricsFields: {
    id: string;
    label: string;
    hiddenOnTable?: boolean;
  }[];
  locationSortOptions: Record<string, string>;
  maxTableColumns: number;
  multiSelectOptions: MultiSelectOption[];
  sideFilter: Record<string, any>;
  unknownLocationControl?: {
    enabled: boolean;
    properties?: {
      id: string;
      identifier?: string;
      name?: string;
    };
  };
};

const initialState: AssetTrackPageConfigContextType = {
  featureButtonsControl: [
    {
      id: "add-item",
      roles: ["admin", "user"],
      disabled: false,
      label: "+ Add Asset"
    }
  ],
  ignoredItemStates: ["removed", "inactive"],
  initialURLParameters: "?locationId&tab=asset",
  isLoading: false,
  listTableOptions: {
    asset: {
      headers: [
        {
          id: "identifier",
          label: "Asset Number",
          isNavLink: true,
          defaultSort: true,
          defaultDirection: "desc",
          defaultColumn: true,
          type: "text"
        },
        {
          id: "sensorProfileVid",
          label: "RFID Tag (EPC)",
          defaultColumn: true,
          type: "text"
        },
        { id: "trackingSessionUpdatedTime", label: "Last Updated", type: "dateTime", defaultColumn: true }
      ],
      pageSize: 10
    },
    assetType: {
      headers: [
        {
          id: "identifier",
          label: "Asset Type",
          defaultSort: true,
          defaultDirection: "desc",
          defaultColumn: true,
          type: "text"
        },
        { id: "totalCount", label: "Quantity", isMetricsField: true, defaultColumn: true, type: "number" },
        { id: "missingCount", label: "Assets Missing", isMetricsField: true, type: "number" }
      ],
      pageSize: 10
    }
  },
  locationCategories: [],
  locationMetricsFields: [{ id: "totalCount", label: "Total Assets" }],
  locationSortOptions: {},
  maxTableColumns: 8,
  multiSelectOptions: [],
  sideFilter: {
    asset: [],
    assetType: []
  },
  unknownLocationControl: {
    enabled: false
  }
};

const AssetTrackPageConfigContext = createContext<AssetTrackPageConfigContextType>(initialState);

const SOLUTION_ID = "asset";
const TRACK_PAGE_FEATURE_ID = "trackPage";

export const useAssetTrackPageConfigContext = () => {
  return useContext(AssetTrackPageConfigContext);
};

export const AssetTrackPageConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const assetSolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const trackPageConfig = assetSolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};

  const [listTableOptions, setListTableOptions] = useState(initialState.listTableOptions);
  const [sideFilter, setSideFilter] = useState(initialState.sideFilter);
  const [locationMetricsFields, setLocationMetricsFields] = useState(initialState.locationMetricsFields);
  const [initialURLParameters, setInitialURLParameters] = useState(initialState.initialURLParameters);
  const [multiSelectOptions, setMultiSelectOptions] = useState(initialState.multiSelectOptions);
  const [locationSortOptions, setLocationSortOptions] = useState<Record<string, string>>(LOCATION_SORT_OPTIONS);
  const [unknownLocationControl, setUnknownLocationControl] = useState(initialState.unknownLocationControl);
  const [maxTableColumns, setMaxTableColumns] = useState(initialState.maxTableColumns);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [ignoredItemStates, setIgnoredItemStates] = useState(initialState.ignoredItemStates);
  const [featureButtonsControl, setFeatureButtonsControl] = useState(initialState.featureButtonsControl);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const { v2: trackPageConfigV2 = {} } = trackPageConfig;
    const {
      listTableOptions: newTableOptions = initialState.listTableOptions,
      sideFilter: newSideFilter = initialState.sideFilter,
      locationMetricsFields: newLocationMetricsFields = initialState.locationMetricsFields,
      initialURLParameters: newInitialURLParameters = initialState.initialURLParameters,
      multiSelectOptions: newMultiSelectOptions = initialState.multiSelectOptions,
      unknownLocationControl: newUnknownLocationControl = initialState.unknownLocationControl,
      maxTableColumns: newMaxTableColumns = initialState.maxTableColumns,
      locationCategories: newLocationCategories = initialState.locationCategories,
      ignoredItemStates: newIgnoredItemStates = initialState.ignoredItemStates,
      featureButtonsControl: newFeatureButtonsControl = initialState.featureButtonsControl
    } = trackPageConfigV2 as AssetTrackPageConfigContextType;

    const newLocationSortOptions: Record<string, string> = { ...LOCATION_SORT_OPTIONS };
    newLocationMetricsFields.forEach((field) => {
      if (!field.hiddenOnTable) {
        newLocationSortOptions[field.id] = field.label;
      }
    });

    setListTableOptions(newTableOptions);
    setSideFilter(newSideFilter);
    setLocationMetricsFields(newLocationMetricsFields);
    setInitialURLParameters(newInitialURLParameters);
    setMultiSelectOptions(newMultiSelectOptions);
    setUnknownLocationControl(newUnknownLocationControl);
    setLocationSortOptions(newLocationSortOptions);
    setMaxTableColumns(newMaxTableColumns);
    setLocationCategories(newLocationCategories);
    setIgnoredItemStates(newIgnoredItemStates);
    setFeatureButtonsControl(newFeatureButtonsControl);

    setIsLoading(false);
  }, [assetSolutionConfigProvider]);

  return (
    <AssetTrackPageConfigContext.Provider
      value={{
        listTableOptions,
        sideFilter,
        locationMetricsFields,
        initialURLParameters,
        multiSelectOptions,
        locationSortOptions,
        unknownLocationControl,
        maxTableColumns,
        locationCategories,
        ignoredItemStates,
        isLoading,
        featureButtonsControl
      }}
    >
      {children}
    </AssetTrackPageConfigContext.Provider>
  );
};
