import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ProcessedItemTypeReport } from "../onboard-items/onboardItems";

type ItemEntryPayload = {
  fields: {
    aggregation_method: string;
    quantity_total: number;
  };
  connections: {
    ofItemType: string;
    hasTransferItemState: {
      fields: {
        transfer_status: string;
      };
      connections: {
        ofItem: string;
      };
    }[];
  };
};

export const createTransferOrder = async (
  transferOrderIdentifier: string,
  completeItemTypeReports: ProcessedItemTypeReport[],
  itemClass: string
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const itemEntries = completeItemTypeReports.reduce((acc: ItemEntryPayload[], itemTypeReport) => {
    const { itemTypeId, epcToItemMap } = itemTypeReport;
    const items = Object.values(epcToItemMap);

    if (itemTypeId && items.length) {
      acc.push({
        fields: {
          aggregation_method: "Manual",
          quantity_total: items.length
        },
        connections: {
          ofItemType: itemTypeId,
          hasTransferItemState: []
        }
      });
    }

    return acc;
  }, []);

  const response = await transferClient.createTransferOrder({
    transferOrderIdentifier,
    transferOrderStatus: "Created",
    itemEntries,
    itemClass
  });

  return (await transferClient.getTransferOrdersByIds([response.createTransferOrder.id]))[0];
};
