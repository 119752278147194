import { TransferOrder } from "@xemelgo/x-client";
import { ItemTypeReport } from "../../../../../../../../data/types";
import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";

export const removeReusedItems = async (
  itemTypeReports: ItemTypeReport[],
  transferOrders: TransferOrder[],
  containerTypeIdentifier: string,
  decrementTotalCountForMovedItems: boolean
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const currentTransferOrderIdSet = new Set(
    transferOrders.map((transferOrder) => {
      return transferOrder.id;
    })
  );

  const itemsIdsToRemove = itemTypeReports.reduce((acc: string[], itemTypeReport) => {
    const { epcToItemMap } = itemTypeReport;

    const reusedItems = Object.values(epcToItemMap)
      .filter((item) => {
        const transferOrderId = item.associatedWithTransferOrder?.id || "";
        return item.id && !currentTransferOrderIdSet.has(transferOrderId);
      })
      .map((item) => {
        return item.id;
      });

    return acc.concat(reusedItems);
  }, []);

  await transferClient.removeItemsFromTransferOrder(
    itemsIdsToRemove,
    containerTypeIdentifier,
    decrementTotalCountForMovedItems
  );
};
