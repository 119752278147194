import React, { useMemo } from "react";
import { KioskItem } from "../../../../../../../../../../data/types";
import { ReactComponent as WarningIcon } from "../../../../../../../../../../../../assets/icons/warning.svg";
import Style from "./MigrateItemsOverview.module.css";

interface MigrateItemsOverviewProps {
  itemsInOtherOrder: KioskItem[];
  headers: {
    id: string;
    subfieldId?: string;
  }[];
  removeItem(item: KioskItem): void;
}

export const MigrateItemsOverview = ({ itemsInOtherOrder, headers, removeItem }: MigrateItemsOverviewProps) => {
  const processedItems = useMemo(() => {
    return itemsInOtherOrder.map((item) => {
      const itemTypeFields = Object.keys(item.type).reduce((acc, key) => {
        const newKey = `type.${key}`;
        acc[newKey] = item.type[key as keyof typeof item.type] || "";
        return acc;
      }, {} as Record<string, string>);

      return {
        ...item,
        ...itemTypeFields,
        transferOrderIdentifier: item.associatedWithTransferOrder?.identifier
      };
    });
  }, [itemsInOtherOrder]);

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <WarningIcon className={Style.icon} />
        {`Items in Another Order (${itemsInOtherOrder.length})`}
      </div>
      <div>
        These items are linked to another order. They will be re-associated with this order unless removed from the
        list.
      </div>
      <div className={Style.table}>
        {processedItems.map((item) => {
          return (
            <div
              key={item.id}
              className={Style.row}
            >
              {headers.map((header, index) => {
                if (header.id === "removeButton") {
                  return (
                    <div
                      key={header.id}
                      className={Style.remove_cell}
                    >
                      <button
                        className={Style.remove_button}
                        type="button"
                        onClick={() => {
                          removeItem(item);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  );
                }

                const value = item[header.id as keyof KioskItem];
                const secondaryValue = item[header.subfieldId as keyof KioskItem];

                const textClassName = index === 0 ? Style.row_bold_text : Style.row_text;

                return (
                  <div
                    key={header.id}
                    className={Style.cell}
                  >
                    <p className={textClassName}>{value}</p>
                    {secondaryValue && <p className={Style.secondary_text}>{secondaryValue}</p>}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
