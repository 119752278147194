import React, { useEffect, useMemo, useState } from "react";
import { TransferOrder } from "@xemelgo/x-client";
import KioskStyle from "../../../../../../../../Kiosk.module.css";
import Style from "./OrderCreationSetup.module.css";
import useTransferOrderActionsConfigContext from "../../../../contexts/transfer-order-actions-config-context";
import AutoSizeTextArea from "../../../../../../../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import useDebounce from "../../../../../../../../../../hooks/use-debounce";
import useAvoidRacingAPIHelper from "../../../../../../../../../../hooks/use-avoid-racing-api-helper";
import { useXemelgoClient } from "../../../../../../../../../../services/xemelgo-service";
import { ReactComponent as WarningIcon } from "../../../../../../../../../../assets/icons/warning.svg";
import ContainerSelectionDropdown from "../../../container-selection-dropdown";
import { ExistingTransferOrderContainer, NewTransferOrderContainer } from "../../../../data/types";

interface OrderCreationSetupProps {
  onConfirm: (
    transferOrder?: TransferOrder,
    transferOrderIdentifier?: string,
    existingContainer?: ExistingTransferOrderContainer,
    newContainer?: NewTransferOrderContainer
  ) => void;
}

export const OrderCreationSetup = ({ onConfirm }: OrderCreationSetupProps) => {
  const executeSearchFn = useAvoidRacingAPIHelper();
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const { customText, containerAssociationOptions } = useTransferOrderActionsConfigContext();
  const [transferOrderIdentifier, setTransferOrderIdentifier] = useState<string>("");
  const [lastCheckedIdentifier, setLastCheckedIdentifier] = useState<string>("");
  const [transferOrder, setTransferOrder] = useState<TransferOrder>();
  const [isUniqueIdentifier, setIsUniqueIdentifier] = useState<boolean>();
  const [existingContainer, setExistingContainer] = useState<ExistingTransferOrderContainer>();
  const [newContainer, setNewContainer] = useState<NewTransferOrderContainer>();
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedIdentifier = useDebounce(transferOrderIdentifier, 250);

  useEffect(() => {
    if (transferOrderIdentifier !== lastCheckedIdentifier) {
      checkIdentifierUniqueness(debouncedIdentifier);
      setLastCheckedIdentifier(debouncedIdentifier);
    }
  }, [debouncedIdentifier, lastCheckedIdentifier, transferOrderIdentifier]);

  const checkIdentifierUniqueness = async (identifer: string) => {
    if (!identifer) {
      return;
    }

    const { result, canceled } = await executeSearchFn(transferClient.getTransferOrderByIdentifiers([identifer]));
    if (!canceled) {
      setIsUniqueIdentifier(result.length === 0);
      setTransferOrder(result[0]);
      setLoading(false);
    }
  };

  const duplicateIdentifierText = useMemo(() => {
    const { container, transferOrderIdentifier: TOIdentifierText, containerIdentifier } = customText;
    const { enabled: containerEnabled, existingContainerDisabled } = containerAssociationOptions;

    return `This ${TOIdentifierText} already exists.${
      containerEnabled
        ? existingContainerDisabled
          ? ` Create a new container below.`
          : ` Specify the ${containerIdentifier} below, or create a new ${container}.`
        : ""
    }`;
  }, [customText, containerAssociationOptions]);

  const isValidTO = debouncedIdentifier || transferOrder;
  const isContainerSelected =
    !containerAssociationOptions.enabled || existingContainer?.identifier || newContainer?.identifier;
  const isVidEntered =
    !containerAssociationOptions.manualTagEntry || existingContainer?.identifier || newContainer?.vid;

  return (
    <>
      <div className={Style.container}>
        <div className={Style.inputs_container}>
          <div>
            <div className={Style.text}>
              {`${customText.transferOrderIdentifierLabel}:`}
              <p className={Style.required_asterisk}>*</p>
            </div>
            <AutoSizeTextArea
              autoFocus
              id="tracking-number"
              value={transferOrderIdentifier}
              onChangeText={(text) => {
                if (text !== transferOrderIdentifier) {
                  setTransferOrder(undefined);
                  setLoading(true);
                  setTransferOrderIdentifier(text);
                }
              }}
            />
          </div>
          {!loading && transferOrderIdentifier && !isUniqueIdentifier && (
            <div className={Style.warning_container}>
              <WarningIcon className={Style.warning_icon} />
              <p>{duplicateIdentifierText}</p>
            </div>
          )}
          {containerAssociationOptions.enabled && (
            <ContainerSelectionDropdown
              transferOrderGroupName={transferOrderIdentifier || transferOrder?.identifier}
              onSelectContainer={setExistingContainer}
              newContainer={newContainer}
              onChangeNewContainer={setNewContainer}
            />
          )}
        </div>
      </div>
      <div className={KioskStyle.footer}>
        <button
          type="button"
          className={KioskStyle.footer_button}
          disabled={loading || !isValidTO || !isContainerSelected || !isVidEntered}
          onClick={() => {
            onConfirm(transferOrder, transferOrderIdentifier, existingContainer, newContainer);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};
