import React, { useState, useEffect } from "react";
import { ComposedChart, Area, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import moment from "moment";
import PropTypes from "prop-types";
import Style from "./BLETemperatureHistoryTable.module.css";
import { generateTemperatureData } from "./utils/generate-temperature-data/generateTemperatureData";
import axios from "axios";

export const BLETemperatureHistoryTable = ({ data, config }) => {
  const [sensorData, setSensorData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // Find the item with dataKey === 'temp'
      const tempData = payload.find((item) => {
        return item.dataKey === "temp";
      });

      if (tempData) {
        return (
          <div className={Style.tooltip}>
            <p>{`Time: ${tempData.payload.time}`}</p>
            <p style={{ color: tempData.color }}>{`Temperature: ${tempData.value}°C`}</p>
          </div>
        );
      }
    }
    return null;
  };

  const formatYAxisLabel = (timeString) => {
    const dateObj = new Date(timeString);
    const formattedLabel = dateObj.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      hour12: false
    });
    return formattedLabel.replace(",", ""); // Remove comma for cleaner formatting
  };

  const fetchData = async () => {
    let last_temperature = data[data.length - 1]?.temp;
    let last_date_time = moment(Date.now()).format("YYYY-MM-DD HH:mm");

    try {
      const response = await axios.get(
        `${config.endpoint}/devices/environmental-sensors?page=0&size=20`, // Replace with your API URL
        {
          headers: {
            apikey: config.apikey // Replace with your auth token
          }
        }
      );

      const result = response.data.sensors.filter((obj) => obj.id === config.sensorId);

      if (result.length > 0) {
        const sensor = result[0];
        last_temperature = sensor?.unverified?.last_temperature;
        last_date_time = moment(sensor?.unverified?.last_date_time).format("YYYY-MM-DD HH:mm");
      }
    } catch (err) {
      // TODO: Won't need to handle right now
    }

    setSensorData({
      last_temperature,
      last_date_time
    });
  };

  return (
    <div className={Style.bottom_table}>
      <div className={Style.title_group}>
        <div className={Style.title_label}>Temperature History</div>
      </div>
      <div className={Style.container}>
        <div className={Style.current_data_container}>
          <p className={Style.label}>CURRENT TEMPERATURE</p>
          <p className={Style.temperature_text}>{`${sensorData?.last_temperature} °C`}</p>
          <p className={Style.label}>{sensorData?.last_date_time}</p>
        </div>
        <div className={Style.chart_container}>
          <ResponsiveContainer
            width="100%"
            height={300}
          >
            <ComposedChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                tickFormatter={formatYAxisLabel}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="yellow"
                stroke="none"
                fillOpacity={0.6}
                fill="green"
              />

              <Area
                type="monotone"
                dataKey="red"
                stroke="none"
                fillOpacity={0.6}
                fill="green"
              />
              <Line
                type="monotone"
                dataKey="temp"
                stroke="#8884d8"
                strokeWidth={2}
                dot={{ r: 4 }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

BLETemperatureHistoryTable.defaultProps = {
  data: generateTemperatureData(-25, 5)
};

BLETemperatureHistoryTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ time: PropTypes.number, temp: PropTypes.number, yellow: PropTypes.number, red: PropTypes.number })
  )
};
