import React, { useContext, useMemo, useState, useEffect } from "react";
import useFetchTransferOrderHook from "../../hooks/use-fetch-transfer-order-hook";
import useTransferOrderTrackPageConfigContext from "../transfer-order-track-page-config-context";
import useTransferOrderTrackPageStateContext from "../transfer-order-track-page-state-context";
import { useSolutionMetricsFilterBuilder } from "../../../../components/filters";
import useFetchTransferOrderCountsHook from "../../hooks/use-fetch-transfer-order-counts-hook";
import useCalculateLocationMetricsHook from "../../hooks/use-calculate-location-metrics-hook";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { SORT_DIRECTION } from "../../../../data/constants";

const TransferOrderTrackPageDataSourceContext = React.createContext();

export const useTransferOrderTrackPageDataSourceContext = () => {
  return useContext(TransferOrderTrackPageDataSourceContext);
};

export const TransferOrderTrackPageDataSourceContextProvider = ({ children }) => {
  const xemelgoClient = useXemelgoClient();

  const {
    tableViewTypeControl,
    sideFilterControl,
    unknownLocationControl,
    queryPageLimit,
    listTableControl,
    isLoading: isConfigLoading
  } = useTransferOrderTrackPageConfigContext();
  const { selectedViewTypeId, startDate, endDate, sideFilterValue } = useTransferOrderTrackPageStateContext();

  const { build: getMetricsFilters } = useSolutionMetricsFilterBuilder();

  const [locationTreeMapState, setLocationTreeMapState] = useState(null);

  useEffect(() => {
    const locationClient = xemelgoClient.getLocationClient();
    locationClient.getLocationTree([]).then((result) => {
      setLocationTreeMapState(result);
    });
  }, []);

  const finalSideFilters = useMemo(() => {
    if (isConfigLoading) {
      return [];
    }
    const filterIdToTypeMap = sideFilterControl.reduce((acc, filter) => {
      acc[filter.id] = filter.type;
      return acc;
    }, {});

    return getMetricsFilters(sideFilterValue, filterIdToTypeMap);
  }, [sideFilterControl, sideFilterValue, isConfigLoading]);

  const selectedViewType = useMemo(() => {
    return tableViewTypeControl.transferOrder.find(({ id }) => {
      return id === selectedViewTypeId;
    });
  }, [tableViewTypeControl, selectedViewTypeId, isConfigLoading]);

  const locationTreeMap = useMemo(() => {
    if (!locationTreeMapState) {
      return null;
    }
    return {
      ...locationTreeMapState,
      [unknownLocationControl?.properties?.id]: {
        ...unknownLocationControl?.properties,
        childLocations: [unknownLocationControl?.properties?.id]
      }
    };
  }, [locationTreeMapState, unknownLocationControl]);

  const fetchTransferOrdersParam = useMemo(() => {
    const filters = [...finalSideFilters];

    if (selectedViewType) {
      filters.push({
        property: "properties.transferStatus",
        values: selectedViewType.includeStatuses,
        operation: "IN"
      });
    }

    const defaultHeader =
      listTableControl.transferOrder.headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) || listTableControl.transferOrder.headers[0];

    const sorting = defaultHeader
      ? {
          property: `properties.${defaultHeader.id}`,
          direction: defaultHeader.defaultDirection === SORT_DIRECTION.descending ? "DESC" : "ASC"
        }
      : undefined;

    return {
      locationIds: [],
      filters,
      minTime: startDate?.getTime(),
      maxTime: endDate?.getTime(),
      locationTreeMap,
      queryPageLimit,
      sorting
    };
  }, [selectedViewType, finalSideFilters, startDate, endDate, locationTreeMap, queryPageLimit, listTableControl]);

  const fetchTransferOrderCountsParam = useMemo(() => {
    return {
      locationIds: [],
      filters: finalSideFilters,
      minTime: startDate?.getTime(),
      maxTime: endDate?.getTime(),
      locationTreeMap
    };
  }, [finalSideFilters, startDate, endDate, locationTreeMap]);

  const transferOrderState = useFetchTransferOrderHook(fetchTransferOrdersParam);
  const transferOrderCountsState = useFetchTransferOrderCountsHook(fetchTransferOrderCountsParam);

  const { currentLocationMetrics } = useCalculateLocationMetricsHook(transferOrderCountsState.dataList);

  return (
    <TransferOrderTrackPageDataSourceContext.Provider
      value={{ transferOrderState, transferOrderCountsState, currentLocationMetrics }}
    >
      {children}
    </TransferOrderTrackPageDataSourceContext.Provider>
  );
};
