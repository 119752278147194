import React, { useEffect, useState } from "react";
import { TransferOrder } from "@xemelgo/x-client";
import { fetchReaderOptions } from "../picklist-verification/utils/fetch-reader-options";
import OrderCreationSetup from "./features/order-creation-setup";
import { TRANSFER_ORDER_STAGES } from "../../data/constants";
import MainPage from "../../../main-page";
import useTransferOrderActionsConfigContext from "../../contexts/transfer-order-actions-config-context";
import useKioskStateContext from "../../../../../../contexts/kiosk-state-context";
import { analyzeItemsForItemTypeReport } from "../../../../../../utils/item-type-report/itemTypeReportHelpers";
import SubmissionWorkflow from "../submission-workflow";
import { ExistingTransferOrderContainer, NewTransferOrderContainer } from "../../data/types";

export const OrderCreation = () => {
  const { defaultStageId } = useTransferOrderActionsConfigContext();
  const {
    itemByTag,
    setItemByTag,
    setItemTypeReports,
    setAvailableReaders,
    setLoading,
    itemTypeReports,
    isSubmitting,
    setIsSubmitting,
    panelValues,
    setPanelValues
  } = useKioskStateContext();
  const [existingTransferOrder, setExistingTransferOrder] = useState<TransferOrder>();
  const [newTransferOrderIdentifier, setNewTransferOrderIdentifier] = useState<string>();
  const [existingContainer, setExistingContainer] = useState<ExistingTransferOrderContainer>();
  const [newContainer, setNewContainer] = useState<NewTransferOrderContainer>();

  useEffect(() => {
    if (!itemByTag) {
      return;
    }

    const reports = analyzeItemsForItemTypeReport(Object.values(itemByTag));
    setItemTypeReports(reports);
  }, [itemByTag]);

  const initializeOrderCreation = async (
    transferOrder?: TransferOrder,
    transferOrderIdentifier?: string,
    container?: ExistingTransferOrderContainer,
    containerToCreate?: NewTransferOrderContainer
  ) => {
    setLoading(true);
    setExistingTransferOrder(transferOrder);
    setNewTransferOrderIdentifier(transferOrderIdentifier);
    setExistingContainer(container);
    setNewContainer(containerToCreate);

    setPanelValues({
      ...panelValues,
      containerIdentifier: container?.identifier || containerToCreate?.identifier,
      transferOrderIdentifiersString: transferOrder?.identifier || transferOrderIdentifier
    });
    const availableReaders = await fetchReaderOptions(defaultStageId);

    setAvailableReaders(availableReaders);
    setLoading(false);
  };

  const onClearItem = (_: string, epc: string) => {
    const newItems = { ...itemByTag };
    delete newItems[epc];
    setItemTypeReports(analyzeItemsForItemTypeReport(Object.values(newItems)));

    const updatedItemByTag = { ...itemByTag };
    delete updatedItemByTag[epc];

    setItemByTag(updatedItemByTag);
  };

  return (
    <>
      {newTransferOrderIdentifier ? (
        <MainPage
          onClearReport={() => {
            setItemTypeReports([]);
          }}
          onClearReportRow={(id) => {
            const reportIndex = itemTypeReports.findIndex((report) => {
              return report.id === id;
            });

            const newReports = [...itemTypeReports];
            newReports.splice(reportIndex, 1);
            setItemTypeReports(newReports);
          }}
          onClearItem={onClearItem}
        />
      ) : (
        <OrderCreationSetup onConfirm={initializeOrderCreation} />
      )}
      {isSubmitting && (
        <SubmissionWorkflow
          transferOrders={existingTransferOrder ? [existingTransferOrder] : []}
          newTransferOrderIdentifier={newTransferOrderIdentifier}
          existingContainer={existingContainer}
          newContainer={newContainer}
          onClose={() => {
            setIsSubmitting(false);
          }}
          stage={TRANSFER_ORDER_STAGES[defaultStageId]}
          onClearItem={onClearItem}
        />
      )}
    </>
  );
};
