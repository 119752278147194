import React, { useState, useEffect } from "react";
import { AutoLogoutFeatureProps } from "./data/types";
import {
  useAutoLogoutFeatureConfigContext,
  AutoLogoutFeatureConfigContextProvider
} from "./contexts/auto-logout-feature-config-context";
import AutoLogoutCountdownModal from "./components/auto-logout-countdown-modal";
import useAutoLogout from "./hooks/use-auto-logout";
import { ACTIVITY_EVENTS, THROTTLE_DELAY } from "./data/constants";
import useThrottle from "../../hooks/use-throttle";
import { TIME_IN_MS } from "../../common/Utilities";

const AutoLogoutFeatureInternal: React.FC<AutoLogoutFeatureProps> = ({ children }) => {
  const {
    timeout: autoLogoutTimeout,
    enabled: autoLogoutEnabled,
    isLoading: isConfigLoading,
    countdownThreshold: autoLogoutCountdownThreshold
  } = useAutoLogoutFeatureConfigContext();

  const [showCountdownModal, setShowCountdownModal] = useState<boolean>(false);

  // Only initialize auto logout when config is loaded
  const { countdownSeconds, handleStayLoggedIn, handleLogout } = useAutoLogout({
    autoLogoutTimeout,
    autoLogoutEnabled: !isConfigLoading && autoLogoutEnabled
  });

  // Show modal when countdown reaches 60 seconds
  useEffect(() => {
    if (!isConfigLoading) {
      if (countdownSeconds <= autoLogoutCountdownThreshold / TIME_IN_MS.SECONDS && !showCountdownModal) {
        setShowCountdownModal(true);
      } else if (countdownSeconds > autoLogoutCountdownThreshold / TIME_IN_MS.SECONDS && showCountdownModal) {
        setShowCountdownModal(false);
      }
    }
  }, [countdownSeconds, showCountdownModal, autoLogoutCountdownThreshold, isConfigLoading]);

  // Handle activity events only when modal is not shown and use throttle to prevent spamming
  const handleUserActivity = useThrottle(() => {
    if (!showCountdownModal) {
      handleStayLoggedIn();
    }
  }, THROTTLE_DELAY);

  useEffect(() => {
    if (!autoLogoutEnabled || isConfigLoading) {
      return () => {};
    }

    if (!showCountdownModal) {
      ACTIVITY_EVENTS.forEach((event) => {
        window.addEventListener(event, handleUserActivity);
      });
    }
    // Cleanup function
    return () => {
      ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, [autoLogoutEnabled, isConfigLoading, showCountdownModal, handleUserActivity]);

  return (
    <div data-cy="auto-logout-feature">
      {children}
      {showCountdownModal && (
        <AutoLogoutCountdownModal
          countdownSeconds={countdownSeconds}
          onStayLoggedIn={() => {
            setShowCountdownModal(false);
            handleStayLoggedIn();
          }}
          onLogout={() => {
            setShowCountdownModal(false);
            handleLogout();
          }}
        />
      )}
    </div>
  );
};

export const AutoLogoutFeature: React.FC<AutoLogoutFeatureProps> = ({ children }) => {
  return (
    <AutoLogoutFeatureConfigContextProvider>
      <AutoLogoutFeatureInternal>{children}</AutoLogoutFeatureInternal>
    </AutoLogoutFeatureConfigContextProvider>
  );
};
