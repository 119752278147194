import { useCallback, useEffect, useRef, useState } from "react";
import { useAuthenticationContext } from "../../../../context/authentication-context/AuthenticationContext";
import { LocalCacheService } from "../../../../services/local-cache-service";
import useBrowserTabCloseTimeoutCheck from "../browser-tab-close-timeout-check";
import useBroadcastChannel from "../../../../hooks/use-broadcast-channel";
import { AutoLogoutBroadcastMessage, UseAutoLogoutProps, UseAutoLogoutReturn } from "./data/types";
import { TIME_IN_MS } from "../../../../common/Utilities";

export const useAutoLogout = ({ autoLogoutTimeout, autoLogoutEnabled }: UseAutoLogoutProps): UseAutoLogoutReturn => {
  const { signOut } = useAuthenticationContext();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const countdownRef = useRef<NodeJS.Timeout | null>(null);
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState<number | null>(Date.now());
  const [countdownSeconds, setCountdownSeconds] = useState<number>(autoLogoutTimeout / 1000);

  const clearAllTimeouts = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
      countdownRef.current = null;
    }
  };

  const resetTimeout = (): number | null => {
    if (!autoLogoutEnabled) {
      return null;
    }

    clearAllTimeouts();

    const newLastActivityTimestamp = Date.now();
    setLastActivityTimestamp(newLastActivityTimestamp);
    setCountdownSeconds(autoLogoutTimeout / TIME_IN_MS.SECONDS);
    countdownRef.current = setInterval(() => {
      const elapsedTime = Date.now() - newLastActivityTimestamp;
      const remainingTime = autoLogoutTimeout - elapsedTime;
      const remainingSeconds = Math.ceil(remainingTime / TIME_IN_MS.SECONDS);
      setCountdownSeconds(remainingSeconds);
    }, TIME_IN_MS.SECONDS);

    timeoutRef.current = setTimeout(() => {
      clearAllTimeouts();
      setCountdownSeconds(0);
      handleLogout();
    }, autoLogoutTimeout);

    return newLastActivityTimestamp;
  };

  useEffect(() => {
    resetTimeout();
    return () => {
      clearAllTimeouts();
    };
  }, [autoLogoutEnabled, autoLogoutTimeout]);

  const handleUserActivityUpdate = () => {
    LocalCacheService.clearCloseBrowserTabTimestamp(); // TODO: Maybe Remove this?
    return resetTimeout();
  };

  const handleLogout = () => {
    clearAllTimeouts();
    signOut();
  };

  const onMessage = useCallback(
    (message: AutoLogoutBroadcastMessage) => {
      if (message.type === "ACTIVITY_UPDATE") {
        handleUserActivityUpdate();
      } else if (message.type === "LOGOUT_REQUEST") {
        handleLogout();
      }
    },
    [handleUserActivityUpdate, handleLogout]
  );

  const { sendMessage } = useBroadcastChannel<AutoLogoutBroadcastMessage>({
    channelName: "autoLogout",
    onMessage,
    enabled: autoLogoutEnabled
  });

  const remainSignIn = useCallback(() => {
    const activityTimestamp = handleUserActivityUpdate();
    if (activityTimestamp) {
      sendMessage({
        type: "ACTIVITY_UPDATE",
        payload: activityTimestamp
      });
    }
  }, [sendMessage]);

  const logout = useCallback(() => {
    handleLogout();
    sendMessage({
      type: "LOGOUT_REQUEST",
      payload: Date.now()
    });
  }, [sendMessage]);

  const onTimeout = useCallback(() => {
    logout();
  }, [logout]);

  useBrowserTabCloseTimeoutCheck({
    autoLogoutTimeout,
    onTimeout,
    enabled: autoLogoutEnabled
  });

  return {
    lastActivityTimestamp,
    countdownSeconds,
    handleStayLoggedIn: remainSignIn,
    handleLogout: logout
  };
};
