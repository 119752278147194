import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { STATUS_COLOR_MAP, TILE_TITLE_MAP, TILE_ID_MAP } from "../../../../data/constants";
import useAssetMapStateContext from "../../../../contexts/asset-map-state-context";
import LoadingTable from "../../../../components/loading-table";
import StatusTile from "../../../../../../components/StatusTile/StatusTile";
import OptionalLink from "../../../../../../components/optional-link/OptionalLink";
import PaginatedList from "../../../../../../components/PaginatedList/PaginatedList";
import Style from "../../../../AssetMap.module.css";
import useAssetMapConfigContext from "../../../../contexts/asset-map-config-context";
import { SORT_DIRECTION } from "../../../../../../data/constants";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";
import useFetchAssetHook from "../../../../hooks/use-fetch-asset-hook";

export const AssetStatusContent = () => {
  const history = useHistory();

  const [selectedMetricTypeId, setSelectedMetricTypeId] = useState(TILE_ID_MAP.overdueCount);

  const { mapRef, selectedLocationId, locationMap, selectedAssetTypeSet, locationToCounts } = useAssetMapStateContext();
  const { assetTableOptions, refreshSoonAge } = useAssetMapConfigContext();

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      assetTableOptions.headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      assetTableOptions.headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [assetTableOptions]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const selectedLocation = useMemo(() => {
    return locationMap[selectedLocationId] || {};
  }, [selectedLocationId, locationMap]);

  const timeExceededMetrics = useMemo(() => {
    return [
      {
        id: TILE_ID_MAP.overdueCount,
        status: "Critical",
        count: locationToCounts[selectedLocationId].overdueCount || 0
      },
      {
        id: TILE_ID_MAP.dueSoonCount,
        status: "Warning",
        count: locationToCounts[selectedLocationId].dueSoonCount || 0
      }
    ];
  }, [locationToCounts, selectedLocationId]);

  const fetchAssetParams = useMemo(() => {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    const exceededTime = currentTime.getTime() + refreshSoonAge;

    let filterString = "";
    switch (selectedMetricTypeId) {
      case TILE_ID_MAP.overdueCount:
        filterString = `exists(properties.dueDate) and properties.dueDate != 0 and properties.dueDate < ${currentTime.getTime()}`;
        break;
      case TILE_ID_MAP.dueSoonCount:
        filterString = `exists(properties.dueDate) and properties.dueDate != 0 and ${currentTime.getTime()} <= properties.dueDate and properties.dueDate <= ${exceededTime}`;
        break;

      default:
        break;
    }

    return {
      locationIds: selectedLocation.childLocations,
      filterString,
      locationTreeMap: locationMap
    };
  }, [selectedMetricTypeId, selectedLocation, locationMap, refreshSoonAge]);

  const { dataList: assets, isLoading: assetsLoading, hasNextPage } = useFetchAssetHook(fetchAssetParams);

  const sortedAssets = useMemo(() => {
    const filteredAssets = assets.filter((asset) => {
      return !selectedAssetTypeSet.size || selectedAssetTypeSet.has(asset.itemTypeIdentifier);
    });
    return getSortedData(filteredAssets);
  }, [order, orderBy, assets, selectedAssetTypeSet]);

  if (selectedLocation === "EmptyLocation") {
    return <div className={Style.no_result_message}> No tracked assets here </div>;
  }

  return (
    <div>
      <div className={`${Style.flex_row} ${Style.status_counts}`}>
        {timeExceededMetrics.map((eachMetric) => {
          return (
            <StatusTile
              key={eachMetric.id}
              active={selectedMetricTypeId === eachMetric.id}
              title={TILE_TITLE_MAP[eachMetric.id]}
              subTitle={eachMetric.count}
              color={STATUS_COLOR_MAP[eachMetric.status]}
              onClick={() => {
                setSelectedMetricTypeId(eachMetric.id);
              }}
            />
          );
        })}
      </div>
      <div className={`${Style.flex_column} ${Style.information_table}`}>
        {assetsLoading || hasNextPage ? (
          <LoadingTable />
        ) : sortedAssets.length ? (
          <PaginatedList
            header={assetTableOptions.headers}
            headerContainerClassName={Style.table_header}
            renderHeader={(eachHeader) => {
              return getSortableHeader(eachHeader, {
                headerContainerClass: `${Style.table_column}`,
                className: Style.table_header_text
              });
            }}
            data={sortedAssets}
            renderItem={(asset) => {
              return (
                <div
                  key={asset.id}
                  className={`${Style.flex_row} ${Style.table_item}`}
                >
                  {assetTableOptions.headers.map((eachHeader) => {
                    const value = asset[eachHeader.id] || eachHeader.defaultValue || "-";
                    return (
                      <div
                        key={eachHeader.id}
                        className={`${Style.flex_row} ${Style.table_column}`}
                      >
                        {eachHeader.id === "identifier" ? (
                          <OptionalLink
                            active={!!asset.id}
                            route={`/asset/detail?itemId=${asset.id}`}
                            onClick={() => {
                              if (asset.id) {
                                history.push(`/asset/detail?itemId=${asset.id}`);
                              }
                            }}
                            className={`${Style.interactable_table_item_text}`}
                            onMouseEnter={() => {
                              mapRef.current.hoverLocation(asset.locationId);
                            }}
                            onMouseLeave={() => {
                              mapRef.current.resetHover();
                            }}
                          >
                            {value}
                          </OptionalLink>
                        ) : eachHeader.type === "date" ? (
                          new Date(value).toLocaleDateString()
                        ) : (
                          value
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }}
            paginatorLocation="top"
            paginatorClassName={Style.paginator}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
