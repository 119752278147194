import { useCallback, useRef } from "react";

/**
 * Hook to throttle function calls
 * @param callback Function to throttle
 * @param delay Delay in milliseconds between allowed calls
 * @returns Throttled version of the callback
 */
export const useThrottle = <T extends (...args: any[]) => any>(callback: T, delay: number): T => {
  const lastCallTime = useRef<number>(0);

  return useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();
      if (now - lastCallTime.current >= delay) {
        callback(...args);
        lastCallTime.current = now;
      }
    },
    [callback, delay]
  ) as T;
};
