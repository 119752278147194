import React, { useState } from "react";
import AddAssetFeature from "../../../../../../../add-asset-feature-v2";
import Style from "../../FeatureButtons.module.css";

type AddButtonProps = {
  label?: string;
};

export const AddButton = ({ label = "+ Add Asset" }: AddButtonProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <button
        type="button"
        className={`${Style.button} ${Style.blue_button}`}
        onClick={() => {
          setShowCreateModal(true);
        }}
      >
        {label}
      </button>
      {showCreateModal && (
        <AddAssetFeature
          onClose={() => {
            setShowCreateModal(false);
            // TODO: Automatically refresh page once the metrics are updated
          }}
        />
      )}
    </>
  );
};
