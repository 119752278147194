import { TIME_IN_MS } from "../../../../common/Utilities";

export const getStatusByExpiredTime = (dueDateTimestamp, defaultExpiredDurationMs = TIME_IN_MS.DAYS * 7) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate = currentDate.getTime();
  let status = "Healthy";

  if (dueDateTimestamp) {
    if (currentDate > dueDateTimestamp) {
      status = "Critical";
    } else if (currentDate <= dueDateTimestamp + defaultExpiredDurationMs) {
      status = "Warning";
    }
  }

  return status;
};

export const getLocationStatus = (locationData, defaultExpiredDurationMs) => {
  const locationStatusDate = locationData.assets.reduce((prevDate, asset) => {
    const { refresh_date: refreshDate } = asset;
    const calibrationDate = refreshDate;
    if (calibrationDate && (!prevDate || calibrationDate < prevDate)) {
      return calibrationDate;
    }
    return prevDate;
  }, null);
  return locationStatusDate ? getStatusByExpiredTime(locationStatusDate, defaultExpiredDurationMs) : "EmptyLocation";
};
