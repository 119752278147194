import { TransferOrder } from "@xemelgo/x-client";
import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import { ProcessedItemTypeReport } from "../onboard-items/onboardItems";

type ExistingEntry = {
  id: string;
  existingItemIdSet: Set<string>;
  quantity: number;
};

type ExistingEntryUpdate = {
  id: string;
  itemIdsToAdd: string[];
  quantity: number;
};

type NewEntry = {
  id: string;
  quantity: number;
  itemIds: string[];
};

// Update item entries if it exists or create new item entry
export const updateTransferOrder = async (
  transferOrder: TransferOrder,
  completeItemTypeReports: ProcessedItemTypeReport[]
) => {
  const transferClient = XemelgoService.getClient().getTransferClient();

  const { id: transferOrderId, hasItemsEntry = [] } = transferOrder;

  const existingEntries = hasItemsEntry.reduce((acc: Record<string, ExistingEntry>, entry) => {
    const { id, ofItemType = [], hasTransferItemState = [], quantity_total: quantity = 0 } = entry;
    const { id: itemTypeId } = ofItemType[0] || {};

    const existingItemIdSet = new Set(
      hasTransferItemState.map((itemState) => {
        return itemState.ofItem?.[0]?.id;
      })
    );

    acc[itemTypeId] = {
      id,
      existingItemIdSet,
      quantity
    };
    return acc;
  }, {});

  const { existingEntryUpdates, newEntries } = completeItemTypeReports.reduce(
    (acc, report) => {
      const { epcToItemMap, itemTypeId } = report;
      const items = Object.values(epcToItemMap);

      if (!items.length) {
        return acc;
      }

      const itemsEntry = existingEntries[itemTypeId];

      const itemIds = items.map((item) => {
        return item.itemId;
      });

      if (itemsEntry) {
        const { id, quantity, existingItemIdSet } = itemsEntry;

        // Filter out items that are already in the transfer order
        const itemIdsToAdd = itemIds.filter((itemId) => {
          return !existingItemIdSet.has(itemId);
        });

        acc.existingEntryUpdates.push({
          id,
          itemIdsToAdd: [],
          quantity: quantity + itemIdsToAdd.length
        });
      } else {
        acc.newEntries.push({
          id: itemTypeId,
          quantity: itemIds.length,
          itemIds: []
        });
      }

      return acc;
    },
    { existingEntryUpdates: [] as ExistingEntryUpdate[], newEntries: [] as NewEntry[] }
  );

  await Promise.all([
    transferClient.updateItemEntriesForTransferOrder(transferOrder.id, existingEntryUpdates),
    transferClient.addItemEntriesToTransferOrder(transferOrder.id, newEntries)
  ]);

  return (await transferClient.getTransferOrdersByIds([transferOrderId]))[0];
};
