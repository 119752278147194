import React from "react";
import Style from "./AutoLogoutCountdownModal.module.css";

interface AutoLogoutCountdownModalProps {
  countdownSeconds: number;
  onStayLoggedIn: () => void;
  onLogout: () => void;
}

export const AutoLogoutCountdownModal: React.FC<AutoLogoutCountdownModalProps> = ({
  countdownSeconds,
  onStayLoggedIn,
  onLogout
}) => {
  return (
    <div
      data-cy="auto-logout-countdown-modal"
      className={Style.overlay}
    >
      <div className={Style.content}>
        <h2 className={Style.title}>Session Timeout Warning</h2>
        <p className={Style.description}>
          Your session will expire in <strong>{countdownSeconds}</strong> seconds due to inactivity. Would you like to
          stay logged in?
        </p>
        <div className={Style.actions}>
          <button
            type="button"
            className={`${Style.button} ${Style.button_outline}`}
            onClick={onLogout}
          >
            Log Out
          </button>
          <button
            type="button"
            className={`${Style.button} ${Style.button_primary}`}
            onClick={onStayLoggedIn}
          >
            Stay Logged In
          </button>
        </div>
      </div>
    </div>
  );
};
