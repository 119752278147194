import React, { useMemo } from "react";
import Style from "./GridView.module.css";
import xemelgoStyle from "../../../../../../styles/variable";
import { multipleSortComparison } from "../../../../../../utils";

type GridCard = {
  id: string;
  label: string;
  type: string;
  index: number;
  primaryColor?: string;
};

interface GridViewProps {
  cards: GridCard[];
  onOptionClick: (optionId: string) => void;
  defaultColorMap?: Record<string, string>;
  defaultIconMap?: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;
}

export const GridView = ({ cards, onOptionClick, defaultColorMap = {}, defaultIconMap = {} }: GridViewProps) => {
  const sortedOptions = useMemo(() => {
    return cards.sort((a, b) => {
      if (a.index === b.index) {
        return multipleSortComparison(["label"])(a, b);
      }
      return a.index - b.index;
    });
  }, [cards]);

  return (
    <div className={Style.grid_container}>
      {sortedOptions.map((option) => {
        const { id, type, primaryColor, label } = option;

        const defaultColor = defaultColorMap[type] || "";
        const primaryColorValue = primaryColor || defaultColor || xemelgoStyle.theme.APP_BLUE;

        const OptionIcon = defaultIconMap[type];

        return (
          <button
            type="button"
            key={id}
            onClick={() => {
              onOptionClick(id);
            }}
            className={Style.action_button}
            style={{
              backgroundColor: primaryColorValue
            }}
          >
            <p className={Style.button_text}>{label}</p>
            <div className={Style.icon_container}>
              {OptionIcon && (
                <OptionIcon
                  className={Style.icon}
                  style={{
                    color: "white"
                  }}
                />
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};
