import React, { useMemo } from "react";
import { CloseRounded, LocationOn } from "@material-ui/icons";
import { SEARCH_TYPE_MAP, STATUS_COLOR_MAP } from "../../data/constants";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";
import LoadingTable from "../../components/loading-table";
import { getStatusByExpiredTime } from "../../utils/get-location-status";
import useAssetMapConfigContext from "../../contexts/asset-map-config-context";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import AssetMapStyle from "../../AssetMap.module.css";
import Style from "./SearchResultContent.module.css";

export const SearchResultContent = () => {
  const {
    mapRef,
    selectedSearchResult,
    searchAssetResults,
    isAssetResultsLoading,
    searchType,
    setSearchString,
    setSelectedSearchResult,
    setShowAssetResults,
    setSearchAssetResults
  } = useAssetMapStateContext();
  const { showAssetStatus, refreshSoonAge } = useAssetMapConfigContext();

  const headers = useMemo(() => {
    const newHeaders = ["Asset #"];

    if (searchType === SEARCH_TYPE_MAP.asset_number) {
      newHeaders.push("Asset Type");
    }
    if (showAssetStatus) {
      newHeaders.push("Status");
    }

    return newHeaders;
  }, [searchType, showAssetStatus, searchAssetResults]);

  return (
    <div>
      <div className={`${AssetMapStyle.flex_row} ${Style.search_result_header}`}>
        <p className={Style.search_result_title}>{`Search results for '${selectedSearchResult.label}'`}</p>
        <div
          className={Style.search_results_close_button}
          onClick={() => {
            setSelectedSearchResult({});
            setShowAssetResults(false);
            setSearchAssetResults([]);
            setSearchString("");
          }}
        >
          <CloseRounded />
        </div>
      </div>

      {isAssetResultsLoading ? (
        <LoadingTable />
      ) : (
        searchAssetResults
          .sort((a, b) => {
            return a.identifier.localeCompare(b.identifier);
          })
          .map((eachLocation) => {
            return (
              <div
                className={`${AssetMapStyle.flex_column}  ${Style.search_result_location_container}`}
                key={eachLocation.id}
                onMouseEnter={() => {
                  mapRef.current.hoverLocation(eachLocation.id);
                }}
                onMouseLeave={() => {
                  mapRef.current.resetHover();
                }}
              >
                <div className={Style.location_header}>
                  <div className={`${AssetMapStyle.flex_row} ${Style.location_point_icon}`}>
                    <LocationOn />
                  </div>
                  <p className={Style.location_result_label}>{eachLocation.identifier || "Unknown Location"}</p>
                  {searchType === SEARCH_TYPE_MAP.asset_type && (
                    <p className={Style.search_count_text}>
                      {`${eachLocation.assets.length} Asset${eachLocation.assets.length > 1 ? "s" : ""}`}
                    </p>
                  )}
                </div>
                <PaginatedList
                  paginatorLocation="top"
                  paginatorClassName={Style.paginator}
                  numItemsPerPage={10}
                  header={headers}
                  data={eachLocation.assets}
                  headerContainerClassName={Style.table_item}
                  renderHeader={(eachHeader) => {
                    return (
                      <p className={`${AssetMapStyle.table_column} ${AssetMapStyle.table_header_text}`}>{eachHeader}</p>
                    );
                  }}
                  renderItem={(eachAsset) => {
                    const { id, identifier, assetType, refresh_date: refreshDate } = eachAsset;
                    const status = getStatusByExpiredTime(refreshDate, refreshSoonAge);

                    return (
                      <div
                        key={identifier}
                        className={`${AssetMapStyle.flex_row} ${Style.table_item}`}
                      >
                        <a
                          href={`/asset/detail?itemId=${id}`}
                          className={`${AssetMapStyle.table_column} ${AssetMapStyle.link}`}
                        >
                          {identifier}
                        </a>
                        {searchType === SEARCH_TYPE_MAP.asset_number && (
                          <div className={`${AssetMapStyle.table_column} ${AssetMapStyle.asset_data_value}`}>
                            {assetType?.identifier}
                          </div>
                        )}
                        {showAssetStatus && (
                          <div className={AssetMapStyle.table_column}>
                            {status ? (
                              <div
                                className={AssetMapStyle.status_pill}
                                style={{
                                  backgroundColor: STATUS_COLOR_MAP[status]
                                }}
                              >
                                {status}
                              </div>
                            ) : (
                              "-"
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            );
          })
      )}
    </div>
  );
};
