export const DEFAULT_MIGRATE_ITEMS_TABLE_HEADERS = [
  {
    id: "type.identifier",
    subfieldId: "type.name"
  },
  {
    id: "transferOrderIdentifier"
  },
  {
    id: "removeButton"
  }
];
