import { useCallback, useEffect, useRef } from "react";
import { BroadcastChannelOptions, UseBroadcastChannelReturn } from "./data/types";
import createBroadcastChannel from "./utils/create-broadcast-channel";

// A React hook that provides a simple interface for cross-tab communication using the BroadcastChannel API
// Usage: const { sendMessage } = useBroadcastChannel({ channelName: 'my-channel', onMessage: (data) => console.log(data), enabled: true })

export const useBroadcastChannel = <TMessage = unknown>({
  channelName,
  onMessage,
  enabled = false
}: BroadcastChannelOptions<TMessage>): UseBroadcastChannelReturn<TMessage> => {
  const channelRef = useRef<BroadcastChannel | null>(null);

  const handleMessage = useCallback(
    (event: MessageEvent<TMessage>) => {
      return onMessage?.(event.data);
    },
    [onMessage]
  );

  // Effect for managing channel lifecycle
  useEffect(() => {
    if (!enabled) {
      // Clean up existing channel if disabled
      if (channelRef.current) {
        channelRef.current.close();
        channelRef.current = null;
      }
      return;
    }

    // Initialize broadcast channel
    channelRef.current = createBroadcastChannel(channelName);

    // Cleanup function for channel
    return () => {
      if (channelRef.current) {
        channelRef.current.close();
        channelRef.current = null;
      }
    };
  }, [channelName, enabled]);

  // Effect for handling message events
  useEffect(() => {
    if (!channelRef.current || !onMessage || !enabled) {
      return;
    }

    channelRef.current.addEventListener("message", handleMessage);
    return () => {
      if (channelRef.current) {
        channelRef.current.removeEventListener("message", handleMessage);
      }
    };
  }, [channelRef.current, handleMessage, enabled]);

  const sendMessage = useCallback(
    (message: TMessage) => {
      if (channelRef.current && enabled) {
        channelRef.current.postMessage(message);
      }
    },
    [enabled]
  );

  return { sendMessage };
};
