import React, { useEffect } from "react";
import MainPage from "../main-page";
import useKioskStateContext from "../../../../contexts/kiosk-state-context";
import { analyzeItemsForItemTypeReport } from "../../../../utils/item-type-report/itemTypeReportHelpers";
import SubmissionModal from "./features/submission-modal";
import useUserLocationContext from "../../../../../../context/user-location-context";
import useKioskConfigContext from "../../../../contexts/kiosk-config-context";
import { defaultFetchData } from "./utils/default-fetch-data/defaultFetchData";

export const CheckoutTable = () => {
  const { userLocation } = useUserLocationContext();
  const { sidePanelAttributes, readerLocationOptionCategory, locationOptionCategory } = useKioskConfigContext();
  const {
    itemByTag,
    setItemByTag,
    setItemTypeReports,
    setOptionTemplateMap,
    setLocationOptions,
    setAvailableReaders,
    setLoading,
    itemTypeReports,
    panelValues,
    setPanelValues
  } = useKioskStateContext();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!itemByTag) {
      return;
    }

    const reports = analyzeItemsForItemTypeReport(Object.values(itemByTag));
    setItemTypeReports(reports);
  }, [itemByTag]);

  const fetchData = async () => {
    setLoading(true);
    const newPanelValues = { ...panelValues };

    const { newOptionsTemplateMap, newLocationOptions, newReaderOptions } = await defaultFetchData(
      userLocation,
      sidePanelAttributes,
      readerLocationOptionCategory,
      locationOptionCategory
    );

    sidePanelAttributes.forEach((attribute) => {
      const { id, defaultValue } = attribute;
      if (defaultValue) {
        newPanelValues[id] = defaultValue;
      }
    });

    setOptionTemplateMap(newOptionsTemplateMap);
    setLocationOptions(newLocationOptions);
    setAvailableReaders(newReaderOptions);
    setPanelValues(newPanelValues);

    setLoading(false);
  };

  const onClearItem = (_, vid) => {
    const newItems = { ...itemByTag };

    delete newItems[vid];
    setItemTypeReports(analyzeItemsForItemTypeReport(Object.values(newItems)));

    const updatedItemByTag = { ...itemByTag };
    delete updatedItemByTag[vid];

    setItemByTag(updatedItemByTag);
  };

  return (
    <>
      <MainPage
        onClearReport={() => {
          setItemTypeReports([]);
        }}
        onClearReportRow={(id) => {
          const reportIndex = itemTypeReports.findIndex((report) => {
            return report.id === id;
          });
          const newReports = [...itemTypeReports];
          newReports.splice(reportIndex, 1);
          setItemTypeReports(newReports);
        }}
        onClearItem={onClearItem}
      />
      <SubmissionModal />
    </>
  );
};
