import React, { useMemo } from "react";
import Style from "./FeatureButtons.module.css";
import useAssetTrackPageConfigContext from "../../../../contexts/asset-track-page-config-context";
import { useRoleAuthorization } from "../../../../../../apps/hooks/useRoleAuthorization";
import { FEATURE_BUTTON_COMPONENT_MAP } from "./data/constants";

export const FeatureButtons: React.FC<{}> = () => {
  const { featureButtonsControl } = useAssetTrackPageConfigContext();

  const { isAuthorized } = useRoleAuthorization({
    config: featureButtonsControl
  });

  const activeButtons = useMemo(() => {
    return featureButtonsControl
      ?.filter(({ id: featureButtonId, disabled }) => {
        return !disabled && isAuthorized(featureButtonId);
      })
      .map(({ id, label, ...restConfig }) => {
        const Component = FEATURE_BUTTON_COMPONENT_MAP[id];
        return (
          <Component
            label={label}
            {...restConfig}
          />
        );
      });
  }, [featureButtonsControl, isAuthorized]);

  if (!activeButtons?.length) {
    return null;
  }

  return <div className={Style.container}>{activeButtons}</div>;
};
