import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ExportIcon } from "../../assets/icons/export.svg";
import Style from "./IssueHistoryFeature.module.css";
import { DEFAULT_ISSUE_TABLE_HEADERS, DEFAULT_ISSUE_TABLE_OPTIONS, ISSUE_TABLE_OPTIONS } from "./data/constants";
import PaginatedListTableWithTypes, { getValueByType } from "../../components/paginated-list-table-with-types";
import ResolveIssueModal from "./components/resolve-issue-modal";
import UpdateCommentModal from "./components/update-comment-modal";
import FreeTextSearchInput from "../../components/free-text-search-input";
import useDebounce from "../../hooks/use-debounce";
import { exportCsv } from "../../common/Utilities";
import { parseItemIssues } from "./utils/parse-item-issues";

export const IssueHistoryFeature = ({ itemIdentifier, issueOptions, issues, isLoading, onIssueUpdate }) => {
  const {
    issueTableHeaders = DEFAULT_ISSUE_TABLE_HEADERS,
    issueTableOptions = DEFAULT_ISSUE_TABLE_OPTIONS,
    resolveCommentEnabled = true
  } = issueOptions || {};

  const [selectedIssue, setSelectedIssue] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 250);

  const augmentedIssues = useMemo(() => {
    return parseItemIssues(issues, issueTableOptions);
  }, [issues]);

  const showOptionsColumn = useMemo(() => {
    return (
      issueTableOptions.length > 0 &&
      augmentedIssues.some((issue) => {
        return issue?.rowOptions.length > 0;
      })
    );
  }, [augmentedIssues]);

  const filteredIssues = useMemo(() => {
    if (!augmentedIssues || !searchInputDebounced) {
      return augmentedIssues;
    }

    return augmentedIssues.filter((issue) => {
      const displayString = issueTableHeaders.reduce((accumulator, eachHeader) => {
        return accumulator + getValueByType(issue, eachHeader, true);
      }, "");

      return displayString.toString().toLowerCase().includes(searchInputDebounced.toLowerCase());
    });
  }, [augmentedIssues, searchInputDebounced]);

  const exportIssuesCsv = () => {
    const csvData = filteredIssues.map((eachData) => {
      return issueTableHeaders.reduce((accumulator, eachHeader) => {
        const { label } = eachHeader;
        accumulator[label] = getValueByType(eachData, eachHeader, true);
        return accumulator;
      }, {});
    });

    exportCsv(
      csvData,
      {
        header: issueTableHeaders.map((eachHeader) => {
          return eachHeader.label;
        }),
        cellDates: true
      },
      `${itemIdentifier} Issues.csv`
    );
  };

  return (
    <div>
      <p className={Style.title}>Issue History</p>
      <div
        className={Style.container}
        data-cy="issue-history-container"
      >
        <div className={Style.table_options_row}>
          <div className={Style.free_text_search_container}>
            <FreeTextSearchInput
              value={searchInput}
              onChangeText={setSearchInput}
              searchInputClassName={Style.free_text_search_input}
            />
          </div>
          <button
            type="button"
            className={Style.button}
            onClick={exportIssuesCsv}
          >
            <ExportIcon className={Style.export_icon} />
            Export
          </button>
        </div>
        <PaginatedListTableWithTypes
          isLoading={isLoading}
          numItemsPerPage={5}
          headers={issueTableHeaders}
          dataList={filteredIssues}
          paginatorLocation="top"
          useV2
          headerRowContainerClassName={Style.header_row_container}
          headerCellContainerClassName={Style.table_header}
          itemCellContainerClassName={Style.table_item_container}
          itemRowContainerClassName={isLoading ? Style.loading_table_item_row : Style.table_item_row}
          emptyListContainerClassName={Style.empty_list_container}
          showRowOptions={showOptionsColumn}
          onRowOptionClick={(option, issue) => {
            setSelectedIssue(issue);
            setSelectedOption(option);
          }}
        />
      </div>
      {selectedIssue && selectedOption?.id === ISSUE_TABLE_OPTIONS.resolve && (
        <ResolveIssueModal
          issue={selectedIssue}
          onClose={() => {
            setSelectedOption(undefined);
            setSelectedIssue(undefined);
          }}
          onConfirm={(status, message) => {
            setSelectedOption(undefined);
            setSelectedIssue(undefined);
            onIssueUpdate(status, message);
          }}
          commentEnabled={resolveCommentEnabled}
        />
      )}
      {selectedIssue && selectedOption?.id === ISSUE_TABLE_OPTIONS.updateComment && (
        <UpdateCommentModal
          issue={selectedIssue}
          onClose={() => {
            setSelectedOption(undefined);
            setSelectedIssue(undefined);
          }}
          onConfirm={(status, message) => {
            setSelectedOption(undefined);
            setSelectedIssue(undefined);
            onIssueUpdate(status, message);
          }}
        />
      )}
    </div>
  );
};

IssueHistoryFeature.defaultProps = {
  issueOptions: {},
  issues: [],
  isLoading: false,
  onIssueUpdate: () => {}
};

IssueHistoryFeature.propTypes = {
  itemIdentifier: PropTypes.string.isRequired,
  issueOptions: PropTypes.shape({
    issueTableHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string
      })
    )
  }),
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      issue_start_time: PropTypes.number,
      issue_end_time: PropTypes.number,
      issue_types: PropTypes.string,
      issue_status: PropTypes.string,
      comment: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  onIssueUpdate: PropTypes.func
};
