import { useEffect, useRef, useState } from "react";
import { PubSub, CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub";
import { Hub, Auth } from "aws-amplify";
import AWS from "aws-sdk";
import configuration from "../../../../configuration.json";
import { IoTTagMessage } from "../../data/types";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  MOUNTED_READER_ACTION,
  MOUNTED_READER_ACTION_STEPS
} from "../../../../constants/mixpanel-constant/mountedReader";
import useAuthenticationContext from "../../../../context/authentication-context";

const RECONNECT_INTERVAL = 3000;
const RETRY_STATES = [ConnectionState.Disconnected, ConnectionState.ConnectionDisrupted, null];

export const useIoTTopic = (onData: (data: IoTTagMessage) => void, topic?: string) => {
  const { cognitoUser } = useAuthenticationContext();
  const [isPolicyAttached, setIsPolicyAttached] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [connectionState, setConnectionState] = useState<ConnectionState | null>(null);
  const subscriptionRef = useRef<ZenObservable.Subscription | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const topicRef = useRef(topic);
  topicRef.current = topic;
  const { sendMixPanelEvent } = useMixpanelContext();

  const grantPolicy = async (): Promise<void> => {
    const credential = await Auth.currentCredentials();
    const { identityId } = credential;
    const iot = new AWS.Iot({
      region: "us-west-2",
      credentials: credential
    });
    await iot
      .attachPolicy({
        policyName: configuration.iot_core_configuration.subscription_policy_name,
        target: identityId
      })
      .promise();
    setIsPolicyAttached(true);
  };

  const removePolicy = async (): Promise<void> => {
    const credential = await Auth.currentCredentials();
    const { identityId } = credential;
    const iot = new AWS.Iot({
      apiVersion: "2015-05-28",
      region: "us-west-2",
      credentials: credential
    });
    await iot
      .detachPolicy({
        policyName: configuration.iot_core_configuration.subscription_policy_name,
        target: identityId
      })
      .promise();
  };

  const createSubscription = () => {
    if (
      !topicRef.current ||
      connectionState === ConnectionState.Connected ||
      connectionState === ConnectionState.Connecting
    ) {
      return;
    }

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    }

    const clientId = `xemelgo-web-client-${cognitoUser.username}-${Date.now()}`;

    subscriptionRef.current = PubSub.subscribe(topicRef.current, {
      clientId
    }).subscribe({
      next: (data: IoTTagMessage) => {
        setError(null);
        onData(data);
      },
      error: (err) => {
        const errorObj = err instanceof Error ? err : new Error("Unknown error occurred");
        sendMixPanelEvent(MOUNTED_READER_ACTION, MOUNTED_READER_ACTION_STEPS.ACTION_FAILED, {
          operation: "subscribeToIoTTopic",
          topic: topicRef.current,
          error: errorObj.message
        });
      }
    });
  };

  useEffect(() => {
    grantPolicy();

    const listener = Hub.listen("pubsub", (data) => {
      const { payload } = data;

      if (payload.event === CONNECTION_STATE_CHANGE) {
        const newConnectionState = payload.data.connectionState as ConnectionState;
        setConnectionState(newConnectionState);

        switch (newConnectionState) {
          case ConnectionState.ConnectionDisrupted:
          case ConnectionState.Disconnected:
            reconnectTimeoutRef.current = setTimeout(createSubscription, RECONNECT_INTERVAL);

            break;
          case ConnectionState.Connected:
          case ConnectionState.Connecting:
          case ConnectionState.ConnectedPendingDisconnect:
          case ConnectionState.ConnectedPendingKeepAlive:
          case ConnectionState.ConnectedPendingNetwork:
          case ConnectionState.ConnectionDisruptedPendingNetwork:
          default:
            break;
        }
      }
    });

    return () => {
      removePolicy();
      listener();

      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }

      if (reconnectTimeoutRef.current) {
        clearInterval(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (topic && isPolicyAttached && RETRY_STATES.includes(connectionState) && !reconnectTimeoutRef.current) {
      createSubscription();
    }
  }, [topic, isPolicyAttached, connectionState]);

  useEffect(() => {
    topicRef.current = topic;

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }

      if (reconnectTimeoutRef.current) {
        clearInterval(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
    };
  }, [topic]);

  return {
    isConnected: connectionState === ConnectionState.Connected,
    error
  };
};
