import { useReducer, useEffect, useRef } from "react";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { ACTION_TYPE, Asset, AssetState as State, AssetAction as Action, UseFetchAssetHookProps } from "./data/types";

const initialState: State = {
  dataList: [],
  isLoading: true,
  hasNextPage: false,
  lastUpdatedTime: null
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return {
        ...state,
        dataList: [],
        isLoading: initialState.isLoading,
        hasNextPage: initialState.hasNextPage,
        lastUpdatedTime: initialState.lastUpdatedTime
      };
    case ACTION_TYPE.FETCHING:
      return { ...state, dataList: [], isLoading: true, hasNextPage: false };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, hasNextPage: true };
    case ACTION_TYPE.PUBLISH_DATA:
      return {
        ...state,
        isLoading: false,
        dataList: [...state.dataList, ...(action.payload?.dataList || [])],
        hasNextPage: false,
        lastUpdatedTime: action.payload?.lastUpdatedTime || null
      };
    default:
      return state;
  }
};

export const useFetchAssetHook = ({
  locationIds: locationIdsParam,
  filterString: filterStringParam,
  locationTreeMap: locationTreeMapParam
}: UseFetchAssetHookProps): State => {
  const xemelgoAppSyncClient = useXemelgoAppsyncClient();
  const startTimeRef = useRef<number>();

  const [state, dispatch] = useReducer(reducer, {
    dataList: initialState.dataList,
    isLoading: initialState.isLoading,
    hasNextPage: initialState.hasNextPage,
    lastUpdatedTime: initialState.lastUpdatedTime
  });

  const fetchItemsByLocationIds = async (locationIds: string[]): Promise<void> => {
    const startTime = Date.now();
    startTimeRef.current = startTime;

    const assetMapClient = xemelgoAppSyncClient.getAssetMapClient();

    dispatch({ type: ACTION_TYPE.FETCHING });

    let nextTokens: string[] = [];

    do {
      dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE });

      const { assets, nextTokens: newNextTokens } = await assetMapClient.getAssetByLocationIds(
        locationIds,
        filterStringParam,
        nextTokens
      );
      if (startTimeRef.current === startTime) {
        dispatch({
          type: ACTION_TYPE.PUBLISH_DATA,
          payload: {
            dataList: assets.map((eachItem: Asset) => {
              const { locationId, detectedAtId } = eachItem || {};
              return {
                ...eachItem,
                locationName: locationTreeMapParam?.[locationId]?.name,
                locationIdentifier: locationTreeMapParam?.[locationId]?.identifier,
                detectedAtName: locationTreeMapParam?.[detectedAtId]?.name,
                detectedAtIdentifier: locationTreeMapParam?.[detectedAtId]?.identifier
              };
            }),
            lastUpdatedTime: Date.now()
          }
        });
      }
      nextTokens = newNextTokens;
    } while (
      startTimeRef.current === startTime &&
      nextTokens.filter((eachToken) => {
        return !!eachToken;
      }).length !== 0
    );
  };

  useEffect(() => {
    if (locationTreeMapParam) {
      fetchItemsByLocationIds(locationIdsParam);
    }
  }, [locationIdsParam, locationTreeMapParam, filterStringParam]);

  return state;
};
