export const ACTION_TYPE = {
  RESET: "reset",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData"
} as const;

export type ActionType = typeof ACTION_TYPE[keyof typeof ACTION_TYPE];

export interface Asset {
  id: string;
  refreshDate?: number;
  locationId: string;
  detectedAtId: string;
  locationName?: string;
  locationIdentifier?: string;
  detectedAtName?: string;
  detectedAtIdentifier?: string;
  [key: string]: any;
}

export interface LocationTreeNode {
  name: string;
  identifier: string;
}

export interface LocationTreeMap {
  [key: string]: LocationTreeNode;
}

export interface AssetState {
  dataList: Asset[];
  isLoading: boolean;
  hasNextPage: boolean;
  lastUpdatedTime: number | null;
}

export interface AssetAction {
  type: ActionType;
  payload?: {
    dataList: Asset[];
    lastUpdatedTime: number;
  };
}

export interface UseFetchAssetHookProps {
  locationIds: string[];
  filterString: string;
  locationTreeMap: LocationTreeMap;
}
