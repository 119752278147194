import React, { useState, useMemo, useCallback } from "react";
import { getFormattedDate, getTimezone } from "common/Utilities";
import EditableTableComponent from "../../components/editable-table/EditableTableComponent";
import { XemelgoService } from "../../services/XemelgoService";
import { CollapsibleListView } from "../../components/collapsible-list-view";
import useSubscriptionConditionParser from "./TimeExceeded/utils/useSubscriptionConditionParser.js";

const UNIT_LABELS = {
  MINUTES: "Min",
  SECONDS: "Sec",
  HOURS: "Hrs",
  DAYS: "Days"
};

const UNIT_LABELS_TO_MINS = {
  [UNIT_LABELS.MINUTES]: 1,
  [UNIT_LABELS.HOURS]: 60,
  [UNIT_LABELS.DAYS]: 1440
};

const TimeExceededRuleContent = ({
  locationTreeMap,
  ruleId,
  ruleConditionsList,
  onLoad: onLoadProp,
  ruleConfig = {}
}) => {
  const [editedTime, setEditedTime] = useState(undefined);
  const { parse: parseConditionsForSubscription } = useSubscriptionConditionParser();

  const getLocationName = useCallback(
    (locationId) => {
      if (!locationTreeMap[locationId]) {
        return "All Locations";
      }
      let displayName = locationTreeMap[locationId].name;
      let parentLocationId = locationTreeMap[locationId].directParentId;
      while (parentLocationId) {
        displayName = `${locationTreeMap[parentLocationId].name} → ${displayName}`;
        parentLocationId = locationTreeMap[parentLocationId].directParentId;
      }
      return displayName;
    },
    [locationTreeMap]
  );

  const allLocationCondition = useMemo(() => {
    return ruleConditionsList.find((rule) => !rule.tags.locationId);
  }, [ruleConditionsList]);

  const locationRules = useMemo(() => {
    return Object.values(
      ruleConditionsList.reduce((accumulator, eachCondition) => {
        const {
          locationId,
          stage,
          threshold = 0,
          thresholdUnits = UNIT_LABELS.MINUTES,
          expeditedWarningThreshold = 0,
          expeditedWarningThresholdUnits = UNIT_LABELS.MINUTES,
          expeditedCriticalThreshold = 0,
          expeditedCriticalThresholdUnits = UNIT_LABELS.MINUTES
        } = eachCondition.tags || {};

        const formattedThreshold = threshold / UNIT_LABELS_TO_MINS[thresholdUnits];
        const formattedExpeditedCriticalThreshold =
          expeditedCriticalThreshold / UNIT_LABELS_TO_MINS[expeditedCriticalThresholdUnits];
        const formattedExpeditedWarningThreshold =
          expeditedWarningThreshold / UNIT_LABELS_TO_MINS[expeditedWarningThresholdUnits];

        accumulator[locationId] = {
          ...accumulator[locationId],
          subscribed: accumulator[locationId]?.subscribed || eachCondition.hasSubscriptions,
          id: [...(accumulator[locationId]?.id || []), eachCondition.id],
          conditionId: [
            ...(accumulator[locationId]?.conditionId || []),
            { ruleConditionId: eachCondition.id, type: `${stage || "allLocation"}Condition` }
          ],
          locationId,
          locationName: getLocationName(locationId),
          [`${stage}Threshold`]: formattedThreshold,
          [`${stage}ThresholdUnits`]: thresholdUnits,
          expeditedCriticalThreshold: formattedExpeditedCriticalThreshold,
          expeditedCriticalThresholdUnits: expeditedCriticalThresholdUnits,
          expeditedWarningThreshold:
            accumulator[locationId]?.expeditedWarningThreshold || formattedExpeditedWarningThreshold,
          expeditedWarningThresholdUnits: expeditedWarningThresholdUnits,
          disableDelete: !locationId,
          disableEdit: !locationId
        };
        return accumulator;
      }, {})
    ).sort((a, b) => {
      return a.locationName.localeCompare(b.locationName, undefined, {
        numeric: true,
        sensitivity: "base"
      });
    });
  }, [ruleConditionsList, getLocationName]);

  const handleSubscriptionChange = async (ruleConditionId, subscribing) => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    const { subscribeIds, unsubscribeIds } = parseConditionsForSubscription(
      ruleConditionId,
      subscribing,
      locationRules
    );

    await RulePageClient.updateSubscriptionForRuleConditions(subscribeIds, unsubscribeIds);
    await onLoadProp();
  };

  const locationOptions = useMemo(() => {
    return Object.values(locationTreeMap)
      .filter((eachLocation) => {
        return (
          eachLocation.childLocations.length <= 1 &&
          !locationRules.find((each) => {
            return each.locationId === eachLocation.id;
          })
        );
      })
      .map((eachLocation) => {
        const { id } = eachLocation;
        return { objectTypeId: id, objectTypeName: getLocationName(id) };
      })
      .sort((a, b) => {
        return a.objectTypeName.localeCompare(b.objectTypeName, undefined, {
          numeric: true,
          sensitivity: "base"
        });
      });
  }, [getLocationName, locationRules]);

  const headers = useMemo(() => {
    const unitOptions = [UNIT_LABELS.MINUTES, UNIT_LABELS.HOURS, UNIT_LABELS.DAYS];
    const temporaryHeaders = [
      {
        displayName: "Location",
        id: "locationName",
        cell: {
          input: "dropdown",
          data: locationOptions,
          display: "text",
          modifiable: false // whether it can be edited after being added
        }
      },
      {
        displayName: "Warning Threshold",
        id: "warningThreshold",
        unitId: "warningThresholdUnits",
        cell: {
          input: "textWithUnit",
          display: "textWithUnit",
          modifiable: true // whether it can be edited after being added
        },
        options: unitOptions
      },
      {
        displayName: "Critical Threshold",
        id: "criticalThreshold",
        unitId: "criticalThresholdUnits",
        cell: {
          input: "textWithUnit",
          display: "textWithUnit",
          modifiable: true // whether it can be edited after being added
        },
        options: unitOptions
      },
      {
        displayName: "Expedited Warning Threshold",
        id: "expeditedWarningThreshold",
        unitId: "expeditedWarningThresholdUnits",
        cell: {
          input: "textWithUnit",
          display: "textWithUnit",
          modifiable: true // whether it can be edited after being added
        },
        options: unitOptions
      },
      {
        displayName: "Expedited Critical Threshold",
        id: "expeditedCriticalThreshold",
        unitId: "expeditedCriticalThresholdUnits",
        cell: {
          input: "textWithUnit",
          display: "textWithUnit",
          modifiable: true // whether it can be edited after being added
        },
        options: unitOptions
      }
    ];
    if (ruleConfig.publishToIoT) {
      temporaryHeaders.unshift({
        displayName: "",
        id: "subscribed",
        cell: {
          input: "switch",
          display: "switch",
          modifiable: true // whether it can be edited after being added
        }
      });
    }
    return temporaryHeaders;
  }, [ruleConfig, locationOptions, UNIT_LABELS]);

  const handleValidationFn = (payloads) => {
    const inputErrorMap = {};

    const validatedObject = {
      errorExists: false,
      errorMap: inputErrorMap
    };

    payloads.forEach((payloadItem) => {
      const inputError = {
        warningThreshold: false,
        criticalThreshold: false,
        expeditedWarningThreshold: false,
        expeditedCriticalThreshold: false,
        locationName: false
      };

      const {
        _event,
        warningThreshold,
        warningThresholdUnits = UNIT_LABELS.MINUTES,
        criticalThreshold,
        criticalThresholdUnits = UNIT_LABELS.MINUTES,

        locationName,
        id: currentRowId,
        expeditedWarningThreshold,
        expeditedWarningThresholdUnits = UNIT_LABELS.MINUTES,

        expeditedCriticalThreshold,
        expeditedCriticalThresholdUnits = UNIT_LABELS.MINUTES
      } = payloadItem;

      if (_event !== "pending_delete") {
        /**
         * (+thresholdValue === +thresholdValue) is called a 'self check' that is used to check if it is a valid number
         * '+variable' converts the value into a number.
         */
        if (!locationName) {
          inputError.locationName = true;
        }
        if (
          warningThreshold === "" ||
          !warningThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+warningThreshold === +warningThreshold) ||
          +warningThreshold <= 0
        ) {
          inputError.warningThreshold = true;
        }
        if (
          expeditedWarningThreshold === "" ||
          !expeditedWarningThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+expeditedWarningThreshold === +expeditedWarningThreshold) ||
          +expeditedWarningThreshold <= 0
        ) {
          inputError.expeditedWarningThreshold = true;
        }
        if (
          criticalThreshold === "" ||
          !criticalThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+criticalThreshold === +criticalThreshold) ||
          +criticalThreshold <= 0 ||
          formatThreshold(warningThreshold, warningThresholdUnits) >=
            formatThreshold(criticalThreshold, criticalThresholdUnits)
        ) {
          inputError.criticalThreshold = true;
        }
        if (
          expeditedCriticalThreshold === "" ||
          !expeditedCriticalThreshold ||
          // eslint-disable-next-line no-self-compare
          !(+expeditedCriticalThreshold === +expeditedCriticalThreshold) ||
          +expeditedCriticalThreshold <= 0 ||
          formatThreshold(expeditedWarningThreshold, expeditedWarningThresholdUnits) >=
            formatThreshold(expeditedCriticalThreshold, expeditedCriticalThresholdUnits)
        ) {
          inputError.expeditedCriticalThreshold = true;
        }

        inputErrorMap[currentRowId] = inputError;
      }
    });

    const BreakForEachLoop = { exception: "Error exists." };

    try {
      Object.keys(inputErrorMap).forEach((key) => {
        if (
          inputErrorMap[key].warningThreshold ||
          inputErrorMap[key].criticalThreshold ||
          inputErrorMap[key].expeditedWarningThreshold ||
          inputErrorMap[key].expeditedCriticalThreshold ||
          inputErrorMap[key].locationName
        ) {
          validatedObject.errorExists = true;
          throw BreakForEachLoop;
        } else {
          validatedObject.errorExists = false;
        }
      });
    } catch (e) {
      alert("Please enter valid values in the highlighted field(s).");
      if (e !== BreakForEachLoop) {
        throw e;
      }
    }

    validatedObject.errorMap = inputErrorMap;

    return validatedObject;
  };

  const formatThreshold = (threshold, units) => {
    const unitsToMinutes = UNIT_LABELS_TO_MINS[units];
    if (!unitsToMinutes) {
      return threshold;
    }
    return parseFloat(threshold) * unitsToMinutes;
  };

  const getCreateRuleConditionPayloads = (condition) => {
    const {
      warningThreshold,
      warningThresholdUnits = UNIT_LABELS.MINUTES,
      criticalThreshold,
      criticalThresholdUnits = UNIT_LABELS.MINUTES,
      expeditedWarningThreshold,
      expeditedWarningThresholdUnits = UNIT_LABELS.MINUTES,
      expeditedCriticalThreshold,
      expeditedCriticalThresholdUnits = UNIT_LABELS.MINUTES
    } = condition;

    const formattedWarningThreshold = formatThreshold(warningThreshold, warningThresholdUnits);
    const formattedCriticalThreshold = formatThreshold(criticalThreshold, criticalThresholdUnits);
    const formattedExpeditedWarningThreshold = formatThreshold(
      expeditedWarningThreshold,
      expeditedWarningThresholdUnits
    );
    const formattedExpeditedCriticalThreshold = formatThreshold(
      expeditedCriticalThreshold,
      expeditedCriticalThresholdUnits
    );

    return [
      {
        name: `timeExceeded_${condition.locationName.replace(/[^\w\s]/gi, "_")}_${condition.locationId}_warning`,
        tags: {
          warningThreshold: formattedWarningThreshold,
          warningThresholdUnits,
          criticalThreshold: formattedCriticalThreshold,
          criticalThresholdUnits,
          expeditedWarningThreshold: formattedExpeditedWarningThreshold,
          expeditedWarningThresholdUnits,
          expeditedCriticalThreshold: formattedExpeditedCriticalThreshold,
          expeditedCriticalThresholdUnits,
          threshold: formattedWarningThreshold,
          thresholdUnits: warningThresholdUnits,
          locationId: condition.locationId,
          locationName: condition.locationName,
          stage: "warning"
        },
        conditions: [
          {
            property: "timeExceededValue",
            op: ">=",
            value: formattedWarningThreshold
          },
          {
            property: "timeExceededValue",
            op: "<=",
            value: formattedCriticalThreshold
          },
          {
            property: "locationId",
            op: "=",
            value: condition.locationId
          }
        ]
      },
      {
        name: `timeExceeded_${condition.locationName.replace(/[^\w\s]/gi, "_")}_${condition.locationId}_critical`,
        tags: {
          criticalThreshold: formattedCriticalThreshold,
          criticalThresholdUnits,
          expeditedCriticalThreshold: formattedExpeditedCriticalThreshold,
          expeditedCriticalThresholdUnits,
          threshold: formattedCriticalThreshold,
          thresholdUnits: criticalThresholdUnits,
          locationId: condition.locationId,
          locationName: condition.locationName,
          stage: "critical"
        },
        conditions: [
          {
            property: "timeExceededValue",
            op: ">",
            value: formattedCriticalThreshold
          },
          {
            property: "locationId",
            op: "=",
            value: condition.locationId
          }
        ]
      }
    ];
  };

  const getUpdateRuleConditionPayloads = (requestPayload) => {
    const {
      warningThreshold,
      warningThresholdUnits,
      criticalThreshold,
      criticalThresholdUnits,
      expeditedWarningThreshold,
      expeditedWarningThresholdUnits,
      expeditedCriticalThreshold,
      expeditedCriticalThresholdUnits
    } = requestPayload;

    const formattedWarningThreshold = formatThreshold(warningThreshold, warningThresholdUnits);
    const formattedCriticalThreshold = formatThreshold(criticalThreshold, criticalThresholdUnits);
    const formattedExpeditedWarningThreshold = formatThreshold(
      expeditedWarningThreshold,
      expeditedWarningThresholdUnits
    );
    const formattedExpeditedCriticalThreshold = formatThreshold(
      expeditedCriticalThreshold,
      expeditedCriticalThresholdUnits
    );

    return requestPayload.conditionId.map((eachId) => {
      let tags = {};
      const conditions = [];
      if (eachId.type === "warningCondition") {
        tags = {
          warningThreshold: formattedWarningThreshold,
          warningThresholdUnits,
          criticalThreshold: formattedCriticalThreshold,
          criticalThresholdUnits,
          expeditedWarningThreshold: formattedExpeditedWarningThreshold,
          expeditedWarningThresholdUnits,
          expeditedCriticalThreshold: formattedExpeditedCriticalThreshold,
          expeditedCriticalThresholdUnits,
          threshold: formattedWarningThreshold,
          thresholdUnits: warningThresholdUnits,
          locationId: requestPayload.locationId,
          locationName: requestPayload.locationName,
          stage: "warning"
        };
        conditions.push({ property: "timeExceededValue", op: ">=", value: tags.warningThreshold });
        conditions.push({ property: "timeExceededValue", op: "<=", value: tags.criticalThreshold });
      } else {
        tags = {
          criticalThreshold: formattedCriticalThreshold,
          criticalThresholdUnits,
          expeditedCriticalThreshold: formattedExpeditedCriticalThreshold,
          expeditedCriticalThresholdUnits,
          threshold: formattedCriticalThreshold,
          thresholdUnits: criticalThresholdUnits,
          locationId: requestPayload.locationId,
          locationName: requestPayload.locationName,
          stage: "critical"
        };

        conditions.push({ property: "timeExceededValue", op: ">", value: tags.criticalThreshold });
      }
      conditions.push({ property: "locationId", op: "=", value: tags.locationId });

      return {
        id: eachId.ruleConditionId,
        tags,
        conditions
      };
    });
  };

  const handleCancel = () => {
    setEditedTime(undefined);
  };

  const renderSettings = () => {
    const { tags = {} } = allLocationCondition || {};
    const { timeToTrigger = 0 } = tags;
    const date = editedTime !== undefined ? editedTime : getFormattedDate(timeToTrigger, "HH:mm");
    return (
      <div className="alerts-settings-container">
        <div className="alerts-settings-text">
          Notification Time
          <div className="switch-helper-text">When should the notification be sent?</div>
        </div>
        <div className="early-expiration-container">
          <div>
            <input
              className={editedTime === "" ? "time_input input-error" : "time_input"}
              onChange={(event) => {
                setEditedTime(event.target.value);
              }}
              id="notfication-time"
              type="time"
              value={date}
            />
            {`(${getTimezone()})`}
          </div>
          {editedTime === "" && <p className="error-text">Enter a time ex. 12:00 AM</p>}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <p className="tabbedSectionComponent-contentTitle">Time Exceeded</p>
        <p style={{ color: "#343434", paddingBottom: 10 }}>
          Mark orders as "Time Exceeded" if orders stay at the location longer than the set threshold.
        </p>
      </div>
      {ruleConfig.publishToIoT && (
        <CollapsibleListView
          className="alert-switches"
          key="stock"
          title="Alert Settings"
          content={renderSettings()}
          openAtStart
        />
      )}
      <EditableTableComponent
        handleSubscriptionChange={handleSubscriptionChange}
        headers={headers}
        dataList={locationRules}
        handleChangesFn={async (conditions) => {
          const RulePageClient = XemelgoService.getClient().getRulePageClient();
          const promiseList = [];
          conditions.forEach((eachCondition) => {
            switch (eachCondition._event) {
              case "pending_new":
                getCreateRuleConditionPayloads(eachCondition).forEach((eachPayload) => {
                  promiseList.push(
                    RulePageClient.createRuleCondition(
                      eachPayload.name,
                      eachPayload.tags,
                      ruleId,
                      eachPayload.conditions,
                      "no_repeat"
                    )
                  );
                });
                break;
              case "pending_update":
                getUpdateRuleConditionPayloads(eachCondition).forEach((eachPayload) => {
                  promiseList.push(
                    RulePageClient.updateRuleCondition(
                      eachPayload.id,
                      undefined,
                      eachPayload.tags,
                      eachPayload.conditions
                    )
                  );
                });
                break;
              case "pending_delete":
                eachCondition.conditionId.forEach((eachId) => {
                  promiseList.push(RulePageClient.removeRuleCondition(eachId.ruleConditionId, false));
                });
                break;
              default:
                break;
            }
          });
          if (editedTime) {
            const splitTime = editedTime.split(":");
            const date = new Date();
            date.setHours(+splitTime[0], +splitTime[1], 0, 0);
            promiseList.push(
              RulePageClient.updateRuleCondition(allLocationCondition.id, undefined, {
                ...allLocationCondition.tags,
                timeToTrigger: date.getTime()
              })
            );
          }
          await Promise.all(promiseList);
          await onLoadProp();
          return [];
        }}
        hasChanges={!!editedTime}
        handleValidationFn={handleValidationFn}
        handleAutoFillFn={(headerId, newValue, data) => {
          const autoFillObject = {
            data,
            changed: true
          };

          if (headerId === "locationName") {
            const itemIndex = locationOptions
              .map((i) => {
                return i.objectTypeName;
              })
              .indexOf(newValue);
            const itemId = locationOptions[itemIndex].objectTypeId;
            data.locationId = itemId;
          }
          autoFillObject.data = data;

          if (data.name === "" && data.threshold === "") {
            autoFillObject.changed = false;
          }

          return autoFillObject;
        }}
        cancelFunc={handleCancel}
      />
    </div>
  );
};

export default TimeExceededRuleContent;
